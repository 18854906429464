export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: ""
        },
        competenceId: {
            default: 0
        },
        label: {
            default: ""
        },
        createdBy: {
            default: null
        },
        createdAt: {
            default: null
        },
        showTrash: {
            default: false
        },
        showTrashPage: {
            default: false
        },
        canDelete: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
            panel: true
        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        deleteItem() {
            this.$emit("deleted", this.id,)
        },
        goToDetail() {
            if (this.showTrashPage) return this.$router.push({ name: "competenceFormTemplate", params: { id: this.id, competenceId:  this.competenceId}, query: { archived: true } })
                return this.$router.push({ name: "competenceFormTemplate", params: { id: this.id, competenceId:  this.competenceId} })
        }
    },
}