import { render, staticRenderFns } from "./CompetenceList.vue?vue&type=template&id=bc9e86ee&"
import script from "./js/competenceList.js?vue&type=script&lang=js&"
export * from "./js/competenceList.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports