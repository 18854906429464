import ProfilForm from "@/components/form/online/profil/ProfilForm.vue"

export default {
    setup() { },

    components: {
        ProfilForm,
    },

    props: {},

    watch: {},

    data() {
        return {}
    },

    created() { },

    mounted() { },

    methods: {}
}