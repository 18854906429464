import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"

export default {
    setup() { },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        isEnabled: {
            default: true
        },
        userEvalId: {
            default: null
        }
    },

    watch: {

    },

    data() {
        return {
            loading: false,
        }
    },

    created() {
    },

    mounted() { },

    methods: {
        close() {
            this.$emit("resetValue")
            this.$emit("update:dialog", false)
        },
        async actionHandler() {
            this.loading = true;
            try {
                if(this.userEvalId == null) return;
                const request = await apiService.put(`/evaluations/${this.userEvalId}?target=userEval`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$emit("setEnableValue", !this.isEnabled)
                this.$emit("update:dialog", false)
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}