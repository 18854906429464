export default {
    setup() {

    },

    components: {

    },

    props: {
        title: {
            default: "Archivage"
        },
        text: {
            default: "---"
        },
        loading: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
            dialog: false,
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.dialog = false;
        },
        actionHandler() {
            this.$emit("disable")
            this.close();
        }
    },
}