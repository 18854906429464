import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: ""
        },
        lastname: {
            default: ""
        },
        firstname: {
            default: ""
        },
        email: {
            default: ""
        },
        createdAt: {
            default: ""
        },
        isArchived: {
            default: false
        },
        disabled: {
            default: false
        },
        role: {
            default: ''
        }
    },

    watch: {

    },

    data() {
        return {
            items: [],
            currentUserRole: ''
        }
    },

    created() {
        const authStore = useAuthStore()
        this.currentUserRole = authStore.info.role;
        
        if (this.isArchived) {
            if (authStore.info.role == "admin") {
                this.items = [
                    { title: 'Restaurer', action: 'restore' },
                    { title: 'Supprimer', action: 'delete' },
                ]
            } else {
                this.items = [
                    { title: 'Restaurer', action: 'restore' },
                ]
            }
        } else {
            if (this.role == 'tutor') {
                this.items = [
                    { title: 'Modifier', action: 'update' },
                    { title: 'Apprenants', action: 'assignLearners' },
                    { title: 'Référentiels', action: 'assignSkillBase' },
                    { title: 'Désactiver', action: 'disable' },
                ]
            } else if(this.role == 'learner') {
                if(authStore.info.role == 'tutor') {
                    this.items = [
                        { title: 'Dossier d\'évaluations', action: 'evaluations' },
                    ]
                } else {
                    this.items = [
                        { title: 'Modifier', action: 'update' },
                        { title: 'Dossier d\'évaluations', action: 'evaluations' },
                        { title: 'Désactiver', action: 'disable' },
                    ]
                }
            } else {
                this.items = [
                    { title: 'Modifier', action: 'update' },
                    { title: 'Désactiver', action: 'disable' },
                ] 
            }
        }
    },

    mounted() {

    },

    methods: {
        actionHandler({ action }) {
            if (this.disabled) return;
            this.$emit(action, { userId: this.id })
        }
    },
}