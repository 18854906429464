import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    props: {
        currentSelected: {
            default() {
                return null;
            }
        },
        title: {
            default() {
                return "Compétence parente"
            }
        }
    },

    watch: {
        async dialog(val) {
            if (val) await this.retrieveCompetences();
        },
        search(val) {
            const filtered = this.items.filter(el => el.label.includes(val));
            this.addForm = filtered.length === 0;

            if (!val || val.trim() === "") {
                this.addForm = false;
                this.showForm = false;
            } else {
                this.label = val;
            }
        }
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            search: '',

            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label' },
                { text: 'Actions', value: 'actions' },
            ],
            items: [],

            label: null,
            evaluationCriteria: null,
            categories: [],

            addForm: false,
            showForm: false,
            rules: { required: value => !!value || 'Champs requis' },
            selectedLabel: '',
            backupSelection: null
        }
    },

    async created() {
        if (this.currentSelected != null) {
            this.selectedLabel = this.currentSelected.label
        }
        await this.retrieveCompetences();
    },

    methods: {
        close() {
            if (this.addForm) {
                this.addForm = false;
                this.showForm = false;
                this.search = null;
                return;
            }
            this.dialog = false;
            if (this.backupSelection != null) {
                this.selectedLabel = this.backupSelection[0].label;
            }

            this.$emit("canceled", null);
        },

        toggleSelected(item) {
            this.items.forEach(el => {
                if (el !== item) {
                    el.selected = false;
                }
            });

            item.selected = !item.selected;
            const selected = this.items.filter(el => el.selected);
            this.$emit("selectedParent", selected.length ? selected : null);
            this.selectedLabel = item.selected ? item.label : '';

        },

        async retrieveCompetences() {
            try {
                this.items = [];
                const request = await apiService.get(`/competences?target=parent`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data.filter(item => item.parent_id === 0);

                this.addForm = this.items.length === 0;

                if (this.currentSelected && this.currentSelected.length) {
                    this.backupSelection = this.currentSelected

                    const current = this.items.find(item => item.id === this.currentSelected[0].id || item.id === this.currentSelected.id);
                    if (current) {
                        this.selectedLabel = current.label;
                        current.selected = true;
                    }
                }

            } catch (error) {
                this.items = [];
                console.error(error);
            }
        },

        customFilter(value, search) {
            const filtered = this.items.filter(el => el.label.includes(search));
            this.addForm = filtered.length === 0;
            return value != null && search != null && typeof value === 'string' && value.toString().includes(search);
        },

        actionHandler() {
            if (this.addForm) {
                this.createCompetence();
                return;
            }
            const selected = this.items.filter(el => el.selected);
            this.$emit("selected", selected);
            this.$emit("confirmed", selected);

            this.addForm = false;
            this.showForm = false;
            this.search = null;
            this.dialog = false;
            if (!selected.length) {
                this.$emit("selectedParent", null);
            }
        },

        async createCompetence() {
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const request = await apiService.post(`/competences`, {
                    label: this.label,
                    evaluation_criteria: this.evaluationCriteria,
                    categories: [],
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items.push({ id: response.data.id, label: this.label, evaluationCriteria: this.evaluationCriteria, selected: true });

                const selected = this.items.filter(el => el.selected);
                this.$emit("selected", selected);
                this.selectedLabel = selected;

                this.$refs.form.reset();
                this.addForm = false;
                this.search = null;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                });
            }
        },
    }
}
