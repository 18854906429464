import SkillBaseList from "@/components/list/skill_base/SkillBaseList.vue"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() { },

    components: {
        SkillBaseList,
    },

    props: {},

    watch: {},

    data() {
        return {
            role: ''
        }
    },

    created() {
        const authStore = useAuthStore()
        this.role = authStore.info.role
     },

    mounted() { },

    methods: {
        goToCreate() {
            this.$router.push({ name: "skillsBasesForm" })
        }
    }
}