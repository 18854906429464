import EvaluationCard from "@/components/card/EvaluationCard.vue"
export default {
    setup() {

    },

    components: {
        EvaluationCard,
    },

    props: {
        evaluations: {
            default() {
                return []
            }
        }
    },
    computed: {

    },

    watch: {

    },

    data() {
        return {

        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        getCompetenceCount(competences) {
            return competences.filter(item => item.is_parent).length;
          }
    },
}