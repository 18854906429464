import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"

import DisableItemDialog from "@/components/dialog/DisableItemDialog.vue"
import OrganizationTutors from "@/components/list/organization/OrganizationTutors.vue";
import OrganizationLearners from "@/components/list/organization/OrganizationLearners.vue";
import OrganizationReferents from "@/components/list/organization/OrganizationReferents.vue";
import OrganizationSideLeftNav from "@/components/navigation/OrganizationSideLeftNav.vue"
import CreateUpdateOrganizationDialog from "@/components/dialog/CreateUpdateOrganizationDialog.vue"
import ExportMonitoringDialog from "@/components/dialog/ExportMonitoringDialog.vue"
import { useAuthStore } from "@/stores/AuthStore";
import { API_URL } from "@/utils/constantes";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        DisableItemDialog,
        OrganizationTutors,
        OrganizationLearners,
        OrganizationSideLeftNav,
        CreateUpdateOrganizationDialog,
        ExportMonitoringDialog,
        OrganizationReferents,
    },

    props: {

    },

    watch: {},

    data() {
        return {
            currentUserRole: null,
            loading: true,
            monitoringDialog: false,
            cover: null,
            item: { id: null, label: "", learnerCount: 0, isArchived: false }
        }
    },

    created() {
        const authStore = useAuthStore();
        this.currentUserRole = authStore.info.role;
        this.retrieveSingleOrganization();
    },

    mounted() { },

    methods: {
        goBack() {
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') this.$router.push({ name: "organizations", replace: true })
            else this.$router.push({ name: "generalOrganization", replace: true });
        },
        async getDistantPath() {
            try {
                if(this.item.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.item.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.cover = objUrl;
            } catch (error) {
                this.cover = "";
            }
        },
        async retrieveSingleOrganization() {
            this.loading = true;
            const authStore = useAuthStore();
            try {
                const request = await apiService.get(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.item = response.data;
                this.getDistantPath();
            } catch (error) {
                if (authStore.info.role != 'admin') this.$emit("authentication", false)
                this.goBack();
            } finally {
                this.loading = false;
            }
        },

        refreshComponent() {
            this.retrieveSingleOrganization();
        },

        async disableOrganization() {
            this.loading = true;
            const authStore = useAuthStore();
            try {
                const request = await apiService.delete(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
                this.$router.push({ name: "singleArchivedOrganization", replace: true, params: { id: this.$route.params.id ?? authStore.info.organization.id} })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    }
}