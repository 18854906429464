export default {
    setup() { },

    components: {},

    props: {
        drawer: {
            default: null
        },
        evalCompetenceRow: {
            default: null
        }
    },

    watch: {
        drawer(val) {
            this.drawerData = val
        },
        drawerData(val) {
            this.$emit("drawerState", val)
        }
    },

    data() {
        return {
            drawerData: null,
        }
    },

    created() { },

    methods: {}
}