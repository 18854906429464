import SingleOrganizationView from '@/views/online/organization/SingleOrganizationView.vue'
import NotificationsView from '@/views/online/notifications/NotificationsView.vue'

const generalRoutes = [
    {
        path: "/organisme",
        name: "generalOrganization",
        component: SingleOrganizationView,
        meta: { requiredAuth: true, roles: ["superadmin","referent", "tutor"] }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: NotificationsView,
        meta: { requiredAuth: true, roles: ["superadmin","referent", "tutor", "learner", "admin"] }
    },
]

export default generalRoutes;