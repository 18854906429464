export default {
    setup() { },

    components: {

    },

    props: {
        selectedDate: {
            default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
        targetRange: {
            default: ''
        }
    },

    watch: {    },

    data() {
        return {
            date: null,
            menu: false,
        }
    },

    created() {
        this.date = this.selectedDate
    },

    mounted() { },

    methods: {
        confirmSeletedDate(val) {
            this.$refs.menu.save(val);
            this.$emit(this.targetRange, val)
        }
    }
}