import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement } from 'chart.js'
import DatePickerForm from "@/components/form/online/datepicker/DatePickerForm.vue"
import CompetenceStatsCard from "@/components/card/CompetenceStatsCard.vue";
import { EventBus } from '@/evt-bus';
import apiService from '@/services/apiService';
import { useAuthStore } from '@/stores/AuthStore';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

export default {
    setup() { },

    components: {
        LineChartGenerator,
        DatePickerForm,
        CompetenceStatsCard,
    },

    props: {},

    watch: {},

    data() {
        return {
            startDate: `${new Date().getFullYear()}-01-01`,
            endDate: `${new Date().getFullYear()}-12-31`,
            competences: [],
            chartData: {
                labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre',],
                datasets: [
                    {
                        label: 'Compétences validées',
                        backgroundColor: '#4BC0C0',
                        data: [],
                        fill: false,
                        borderColor: '#4BC0C0',
                        tension: 0.1,
                    },
                    {
                        label: 'Compétences assignées',
                        backgroundColor: '#9F70FF',
                        data: [],
                        fill: false,
                        borderColor: '#9F70FF',
                        tension: 0.1,
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        title: {
                            display: true,
                            text: 'Compétences',
                            color: '#15267A'
                        }
                    },
                    x: {
                        min: 0,
                        title: {
                            display: true,
                            text: 'Temps',
                            color: '#15267A'
                        }
                    },
                }
            }
        }
    },

    created() {
        this.retrieveStats();
    },

    mounted() { },

    methods: {
        startRange(val) {
            this.startDate = val;
        },
        endRange(val) {
            this.endDate = val
        },
        setDefault() {
            this.chartData = {
                labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre',],
                datasets: [
                    {
                        label: 'Compétences validées',
                        backgroundColor: '#4BC0C0',
                        data: [],
                        fill: false,
                        borderColor: '#4BC0C0',
                        tension: 0.1,
                    },
                    {
                        label: 'Compétences assignées',
                        backgroundColor: '#9F70FF',
                        data: [],
                        fill: false,
                        borderColor: '#9F70FF',
                        tension: 0.1,
                    }
                ]
            }
        },
        async retrieveStats() {
            try {
                const authStore = useAuthStore();

                this.competences = [];
                this.setDefault();
                const request = await apiService.post(`/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/learners/${this.$route.params.learnerId ?? authStore.info.id}/stats`, { startDate: this.startDate, endDate: this.endDate });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.chartData = response.chartData;
                this.competences = response.validCompetences
            } catch (error) {
                this.setDefault();
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        }
    }
}