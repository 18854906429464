import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import { NAV_ADMIN, NAV_LEARNER, NAV_REFERENT, NAV_TUTOR, routeList } from "@/utils/constantes";
export default {
    setup() {

    },

    components: {
    },

    props: {},

    watch: {
        '$route'() {
            this.setMenu();
            this.setActiveClass()
        }
    },

    data() {
        return {
            showCenter: false,
            isActiveProfil: false,
            isActiveNotif: false,
            unreadCount: 0,
            normalLinks: [],
            profilItems: [
                { title: 'Gérer mon profil', action: 'profil', actionType: 'page' },
                { title: 'Déconnexion', action: '', actionType: 'logout' },
            ],
            refreshInterval: null,
        };
    },

    created() {
        this.setMenu();
        this.setActiveClass();
        this.fetchUnreadCount();

        EventBus.$on('updateReadCount', () => {
            this.fetchUnreadCount();

            if (this.refreshInterval != null) clearInterval(this.refreshInterval)
            this.refreshInterval = setInterval(() => {
                this.fetchUnreadCount();
            }, 60000)
        })

        if (this.refreshInterval != null) clearInterval(this.refreshInterval)
        this.refreshInterval = setInterval(() => {
            this.fetchUnreadCount();
        }, 60000)
    },

    mounted() { },

    methods: {
        getFilePath(file) {
            return `${location.origin}${file}`
        },
        actionHandler({ action, actionType }) {
            switch (actionType) {
                case "logout":
                    this.logout();
                    break;
                case "page":
                    if (this.$route.name == action) return;
                    this.$router.push({ name: action, replace: true })
                    break;
                default:
                    break;
            }
        },
        openInNewTab(item) {
            let route = routeList.find(el => el.name == item.action)
            const url = this.$router.resolve(route.path).href;
            window.open(url, '_blank');
        },
        setMenu() {
            const authStore = useAuthStore();
            switch (authStore.info.role) {
                case "superadmin":
                    this.normalLinks = NAV_ADMIN;
                    break;
                case "admin":
                    this.normalLinks = NAV_ADMIN;
                    break;
                case "referent":
                    this.normalLinks = NAV_REFERENT;
                    break;
                case "tutor":
                    this.normalLinks = NAV_TUTOR;
                    break;
                case "learner":
                    this.normalLinks = NAV_LEARNER;
                    break;
                default:
                    this.normalLinks = [{ title: 'Tableau de bord', action: 'organizations', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-home" },]
                    break;
            }
        },
        setActiveClass() {
            this.normalLinks.forEach(element => {
                element.isActive = false;
            });

            const evaluationsRoutes = ["evaluations", "evaluationForm", "evaluationModels", "evaluationModelForm", "evaluationLearnerFormViewForTutor", "evaluationLearnerFormView"];
            const organizationsRoutes = ["organizations", "singleOrganization", "singleArchivedOrganization"];
            const skillBaseRoutes = ["skillsBases", "skillsBasesForm"];
            const competenceRoutes = ["competences", "competenceForm", "competenceModels", "competenceFormTemplate"];
            const administrationRoutes = ["administrations"];
            const referenceLibrariesRoutes = ["referenceLibraries", "referenceLibrariesForm"]

            let targetRoute = this.$route.name;

            if (evaluationsRoutes.includes(this.$route.name)) targetRoute = "evaluations"
            if (organizationsRoutes.includes(this.$route.name)) targetRoute = "organizations"
            if (skillBaseRoutes.includes(this.$route.name)) targetRoute = "skillsBases"
            if (competenceRoutes.includes(this.$route.name)) targetRoute = "competences"
            if (administrationRoutes.includes(this.$route.name)) targetRoute = "administrations"
            if (referenceLibrariesRoutes.includes(this.$route.name)) targetRoute = "referenceLibraries"

            const profilRoutes = ["profil"];
            const notificationRoutes = ["notifications"];
            this.isActiveProfil = false;
            this.isActiveNotif = false;

            if (profilRoutes.includes(this.$route.name)) {
                this.isActiveProfil = true;
                return;
            }

            if (notificationRoutes.includes(this.$route.name)) {
                this.isActiveNotif = true;
                return;
            }

            const activeElement = this.normalLinks.find(el => el.action == targetRoute && el.title != "Accueil")
            if (activeElement) activeElement.isActive = true;
            else if (!activeElement && this.normalLinks[1]) this.normalLinks[1].isActive = true;
        },
        logout() {
            if (this.refreshInterval != null) clearInterval(this.refreshInterval)
            this.$emit("authentication", false)
        },
        goToNotifications() {
            if (this.$route.name == "notifications") return;
            this.$router.push({ name: "notifications", replace: true })
        },
        async fetchUnreadCount() {
            try {
                const request = await apiService.get('/notifications?target=count');
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.unreadCount = response.data;
            } catch (error) {
                this.unreadCount = 0;
            }
        }
    },
};