export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        title: {
            default: "---"
        },
        btnText: {
            default: "---"
        },
        btnColor: {
            default: "grey"
        },
        text: {
            default: "---"
        },
        isRestore: {
            default: false
        },
        loading: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        actionHandler() {
            this.$emit("confirmDeletion")
            this.close();
        }
    },
}