import ConfirmAccountForm from "@/components/form/offline/ConfirmAccountForm.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() { },

    components: {
        ConfirmAccountForm,
    },

    props: {},

    watch: {},

    data() {
        return {}
    },

    created() {
        if (authTokenService.getToken()) {
            return this.$router.push({ name: 'dashboard', replace: true });
        }
    },

    mounted() { },

    methods: {

    }
}