import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

import apiService from "@/services/apiService";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignCategoriesDialog from "@/components/dialog/AssignCategoriesDialog.vue";
import AssignSubCompetencesDialog from "@/components/dialog/AssignSubCompetencesDialog.vue";
import AssignParentCompetenceDialog from "@/components/dialog/AssignParentCompetenceDialog.vue";
import AssignFormTemplateToCompetenceDialog from "@/components/dialog/AssignFormTemplateToCompetenceDialog.vue";
import ImportCompetencesDialog from "@/components/dialog/ImportCompetencesDialog.vue";
import CompetenceCard from "@/components/card/CompetenceCard.vue";
import CompetenceFormTemplateCard from "@/components/card/CompetenceFormTemplateCard.vue";
import CompetenceCommentsDrawer from "@/components/drawer/CompetenceCommentsDrawer.vue";

export default {
    setup() {

    },

    components: {
        ImportCompetencesDialog,
        AssignCategoriesDialog,
        RestoreOrDeleteDialog,
        CompetenceCard,
        CompetenceFormTemplateCard,
        CompetenceCommentsDrawer,
        AssignFormTemplateToCompetenceDialog,
        AssignSubCompetencesDialog,
        AssignParentCompetenceDialog
    },

    props: {},

    watch: {
        '$route'() {
            this.$router.go(0)
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            loadingBtn: false,
            id: 0,
            label: null,
            evaluationCriteria: null,
            isEnabled: true,
            isArchived: false,
            categories: [],
            backupCategories: [],
            rules: { required: value => !!value || 'Champs requis', },
            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            role: '',
            creator: null,

            openedDrawer: false,
            isAuthorizedToComment: false,

            subCompetences: [],
            parentId: 0,
            isParent: false,
            hasParent: false,
            parentCompetence: null,
            backupFormTemplates: [],

            formTemplates: [],
            isCompetenceCreation: false,
            parent: {}

        }
    },

    async created() {
        if (this.$route.params.id) {
            await this.retrieveInfo();

        } else {
            this.isCompetenceCreation = true
        }

    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        goToFormTemplateCreationPage() {
            return this.$router.push({ name: "competenceFormTemplate", params: { competenceId: this.id } })
        },
        canceledFormTemplateSelection() {
            this.formTemplates = this.backupFormTemplates;
        },
        confirmed(selected) {
            this.backupCompetences = selected;
        },
        confirmedFormTemplates(selected) {
            this.backupFormTemplates = selected
            this.formTemplates = selected;
            this.formTemplatePanel = Array.from(Array(this.formTemplates.length).keys());
        },
        selectedCategories(selected) {
            this.categories = selected;
        },
        selectedSubCompetences(selected) {
            this.subCompetences = selected;
            this.panel = Array.from(Array(this.subCompetences.length).keys())

        },
        selectedFormTemplates(selected) {
            this.formTemplates = selected;
            this.formTemplatePanel = Array.from(Array(this.formTemplates.length).keys());

        },
        selectedParentCompetence(selected) {
            this.parentCompetence = selected;
            if (this.parentCompetence != null) {
                this.hasParent = true;
                this.parentId = this.parentCompetence[0].id
            } else {
                this.hasParent = false;
                this.parentId = 0;
            }
        },
        canceled() {
            this.categories = this.backupCategories;
        },
        showComments() {
            !this.openedDrawer ? this.openedDrawer = true : this.openedDrawer = false
        },
        updateCommentDrawer(val) {
            this.drawerComments = val;
            if (!val) this.selectedCompetence = null;
        },
        async verifyAuthorizationToComment() {
            const authStore = useAuthStore();
            this.currentUser = authStore.info;
            let organization = authStore.info.organization;

            if (this.creator != null) {
                this.isAuthorizedToComment = (organization != null && organization.id == this.creator.organization.id) ? true : false;
            } else {
                this.isAuthorizedToComment = (organization != null && organization.id == this.currentUser.organization.id) ? true : false;
            }
        },
        async retrieveInfo() {
            await this.retrieveSingle();
            this.verifyAuthorizationToComment();
        },
        async retrieveSingle() {
            this.loading = true;
            try {
                const authStore = useAuthStore();
                this.role = authStore.info.role;

                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const request = await apiService.get(`/competences/${this.$route.params.id}${target}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.id = response.data.id
                this.parentId = response.data.parent_id
                this.isParent = response.data.is_parent
                this.parent = response.data.parent
                this.label = response.data.label
                this.subCompetences = response.data.children
                this.evaluationCriteria = response.data.evaluationCriteria
                this.isEnabled = response.data.isEnabled
                this.isArchived = response.data.isArchived
                this.creator = response.data.createdBy ?? null

                this.parentCompetence = this.parent;

                this.categories = [];

                for (let i = 0; i < response.data.categories.length; i++) {
                    const element = response.data.categories[i];
                    element.category.selected = true;
                    this.categories.push(element.category)
                }

                this.backupCategories = this.categories;

                this.formTemplates = response.data.form_templates;
                this.backupFormTemplates = this.formTemplates;
                this.formTemplatePanel = Array.from(Array(this.formTemplates.length).keys());
                this.panel = Array.from(Array(this.subCompetences.length).keys())

            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async deleteSubCompetenceAssignation(id) {
            this.subCompetences = this.subCompetences.filter(subCompetence => subCompetence.id !== id);
        },
        async deleteFormTemplateAssignation(id) {
            this.formTemplates = this.formTemplates.filter(formTemplate => formTemplate.id !== id);
        },
        async actionHandler() {
            this.loadingBtn = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const selectedCategories = [];
                for (let i = 0; i < this.categories.length; i++) {
                    const element = this.categories[i];
                    selectedCategories.push(element.id);
                }

                const selectedSubCompetences = [];
                for (let i = 0; i < this.subCompetences.length; i++) {
                    const element = this.subCompetences[i];
                    selectedSubCompetences.push(element.id);
                }


                const selectedFormTemplates = [];
                for (let i = 0; i < this.formTemplates.length; i++) {
                    const element = this.formTemplates[i];
                    selectedFormTemplates.push(element.id);
                }

                const url = this.$route.params.id ? `/competences/${this.$route.params.id}` : `/competences`
                const request = await apiService.post(url, {
                    label: this.label,
                    evaluation_criteria: this.evaluationCriteria,
                    categories: selectedCategories,
                    children: selectedSubCompetences,
                    formTemplates: selectedFormTemplates,
                    parent_id: this.parentId
                })

                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$refs.form.reset();
                    this.categories = [];
                    this.backupCategories = [];
                    this.backupFormTemplates = [];
                    this.formTemplates = [];
                    this.subCompetences = [];
                    this.parentCompetence = null;
                } else {
                    this.defaultDisable = true;
                }
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/competences/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async restoreHandler() {
            try {
                const request = await apiService.put(`/competences/${this.$route.params.id}?target=restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async softDeleteHandler() {
            try {
                const request = await apiService.delete(`/competences/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id }, query: { archived: true } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async hardDeleteHandler() {
            try {
                const request = await apiService.delete(`/competences/${this.$route.params.id}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: 'competences', replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}