import { useAuthStore } from "@/stores/AuthStore";
import CompetenceModelFormList from "@/components/list/competence/CompetenceModelFormList.vue";


export default {
    setup() { },

    components: {
        CompetenceModelFormList,
    },

    props: {},

    watch: {

    },

    data() {
        return {
            role: '',
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
    },


    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        goToCreate() {
            this.$router.push({ name: 'competenceFormTemplate' })
        },
    }
}