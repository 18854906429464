import { fieldMixin } from "@/utils/fieldMixin";

export default {
  setup() { },
  mixins: [fieldMixin],
  data() {
    return {
      fieldData: {},
      newColumn: '',
      newRow: '',
      fieldValue: [],
      colValues: [],
      rowValues: [],
      rules: {
        required: value => !!value || 'Ce champ est requis',
      },
    };
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'row' },
        ...this.field.columns.map((column) => ({ text: column, value: column })),
      ];
    },
   
  
  },
  mounted() {
    if (this.field.value) {
      this.checkValues(this.field.value);
    }
  },
  created() {
    this.fieldData = this.field
    this.colValues = this.field.columns
    this.rowValues = this.field.rows
  },
  methods: {
    checkValues(values) {
      values.forEach(value => {
        if (!this.fieldValue.includes(value)) {
          this.fieldValue.push(value);
        }
      });
    },
    setValue(newValue) {
      const index = this.fieldValue.indexOf(newValue);

    

      if (!this.isDisable){
        if (this.fieldData.length > 0) {
          if (index !== -1) {
            this.fieldValue.splice(index, 1);
          } else {
            this.fieldValue.push(newValue)
          }
        }
        this.field.value = this.fieldValue
        
        this.$emit('edit-competence-field', this.field, this.fieldIndex, this.structureIndex);
      }

    },
    submiteEditedField() {
      this.$emit('edit-live-field', this.field, this.fieldIndex);
      this.$emit('edit-competence-field', this.field, this.fieldIndex, this.structureIndex);

    },
    addColumn() {
      this.fieldData.columns.push(this.newColumn);
      this.newColumn = '';
    },
    setColumn(column, index) {
      this.$set(this.fieldData.columns, index, column);
    },
    setRow(row, index) {
      this.$set(this.fieldData.rows, index, row);
    },
    removeColumn(index) {
      this.fieldData.columns.splice(index, 1);
    },
    addRow() {
      this.fieldData.rows.push(this.newRow);
      this.newRow = '';
    },
    removeRow(index) {
      this.fieldData.rows.splice(index, 1);
    },
    deleteField(index) {
      this.$emit('delete-field', index);
    },
  }
}