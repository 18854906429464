import TextField from '@/components/form/online/fields/TextField.vue';
import TextAreaField from '@/components/form/online/fields/TextAreaField.vue';
import SelectField from '@/components/form/online/fields/SelectField.vue';
import CheckboxGridField from '@/components/form/online/fields/CheckboxGridField.vue';
import StarRatingField from '@/components/form/online/fields/StarRatingField.vue';
import { formMixin } from '@/utils/formMixin';

export default {
    components: {
        TextField,
        TextAreaField,
        SelectField,
        CheckboxGridField,
        StarRatingField,
      },
  mixins: [formMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    fields: {
      default: false
    },
   
  },
  watch: {
 
  },
  data() {
    return {
        fieldToAdd: null,
        fieldList: null,
      

  } },
  mounted() {


  },
  created() {
    this.fieldToAdd = null
  },
  beforeDestroy() {
  },
  methods: {
    close() {
        this.$emit("update:dialog", false);
      },
  
      addField(type) {
        const fieldNames = {
          TextField: 'Texte',
          TextAreaField: 'Texte Area',
          CheckboxGrid: 'Sélection',
          checkboxGrid: 'Grille de cases',
          StarRatingField: 'Notation par étoiles'
        };
  
        const newField = {
          label: "",
          type: type,
          question: `${fieldNames[type] || type.charAt(0).toUpperCase() + type.slice(1)}`,
          placeholder: `Entrez une valeur ${type}`,
          order: this.fields.length + 1,
          options: [],
          required: false,
          rows: type === 'CheckboxGrid' ? [] : [],
          columns: type === 'CheckboxGrid' ? [] : [],
          maxStars: type === 'StarRatingField' ? 5 : undefined // Default maxStars to 5 for starRating
        };
    
        this.fieldToAdd = newField;

        if (type === 'CheckboxGrid') {
          this.$set(this.formData, newField.label, []);
        } else if (type === 'StarRatingField') {
          this.$set(this.formData, newField.label, 0);
        } else {
          this.$set(this.formData, newField.label, '');
        }
        this.$emit('formData', this.formData)

      },
      async submitField() {
        this.$emit('create-field', this.fieldToAdd)

        this.close();
      }

   
  }
};
