import ReferenceLibraryList from "@/components/list/reference_library/ReferenceLibraryList.vue"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() { },

    components: {
        ReferenceLibraryList,
    },

    props: {},

    watch: {},

    data() {
        return {
            role: ''
        }
    },

    created() {
        const authStore = useAuthStore()
        this.role = authStore.info.role
     },

    mounted() { },

    methods: {
        goToCreate() {
            this.$router.push({ name: "referenceLibrariesForm" })
        }
    }
}