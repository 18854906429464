import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        userId: {
            default: null
        },
        isRestore: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
            loading: false
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        async actionHandler() {
            this.loading = true
            const authStore = useAuthStore();

            try {
                const urlPart = this.isRestore ? "/restore" : "?target=confirm"
                let request;
                if (this.isRestore) {
                    request = await apiService.put(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members/${this.userId}${urlPart}`)
                } else {
                    request = await apiService.delete(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members/${this.userId}${urlPart}`)
                }
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$emit("refresh")
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}