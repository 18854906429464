import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false,
        },
        isLearner: {
            default: false,
        },
        titleCard: {
            default: "---"
        }
    },

    watch: {

    },

    data() {
        return {
            valid: true,
            loading: false,
            importFile: null,
            rules: { required: value => !!value || 'Champs requis', },
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.loading = false;
            this.$refs.form.reset();
            this.$emit('update:dialog', false)
        },
        getFilePath(file) {
            return `${location.origin}/${file}`
        },
        async actionHandler() {
            this.loading = true;
            const authStore = useAuthStore();

            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const urlPart = this.isLearner ? 'type=learner' : 'type=tutor'
                const formData = new FormData();
                formData.append('importFile', this.importFile)
                const request = await apiService.postFile(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members/import?${urlPart}`, formData)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })

                this.$emit("refresh");
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}