import { useAuthStore } from "@/stores/AuthStore";
import OrganizationList from "@/components/list/organization/OrganizationList.vue"
import CreateUpdateOrganizationDialog from "@/components/dialog/CreateUpdateOrganizationDialog.vue"

export default {
    setup() { },

    components: {
        OrganizationList,
        CreateUpdateOrganizationDialog,
    },

    props: {

    },

    watch: {},

    data() {
        return {
            role: null,
            isRefresh: false
        }
    },

    created() { 
        const authStore = useAuthStore();
        this.role = authStore.info.role;

    },

    mounted() { },

    methods: {
        setIsRefresh(refresh) {
            this.isRefresh = refresh;
        }
    }
}