import AssignOneFormTemplateToCompetenceDialog from "@/components/dialog/AssignOneFormTemplateToCompetenceDialog.vue"

export default {
    setup() {

    },

    components: {
        AssignOneFormTemplateToCompetenceDialog
    },

    props: {
        id: {
            default: 0
        },
        label: {
            default: ""
        },
        description : {
            default: null
        },
        createdBy: {
            default: null
        },
        createdAt: {
            default: null
        },
        showTrash: {
            default: false
        },
        canDelete: {
            default: false
        },
        competenceFormTemplateChoosed: {
            default: null
        },
        isParent: {
            default: false
        },
        hasChildren: {
            default: false
        },
        isVisible: {
            default: false
        }

       
        
    },

    watch: {

    },

    data() {
        return {
            panel: true,
            assignFormTemplateDialog: false,
            formTemplate: null,
            formTemplateLabel : "",
            formTemplateSelected: null,
            childrenVisibility: {} // Suivi de la visibilité des enfants
        }
    },

    created() {
        if (this.competenceFormTemplateChoosed != null && this.competenceFormTemplateChoosed[0]){
            this.formTemplateLabel = this.competenceFormTemplateChoosed[0].label
            this.formTemplate = this.competenceFormTemplateChoosed[0]
        }
    },
    
    mounted() {

    },

    methods: {
        deleteItem() {
            this.$emit("deleted", this.id,)
        },
        confirmedSelectedFormTemplate(selected){
            this.formTemplate = selected
            this.$emit("setCompetenceFormTemplate", selected)
        },
        selectedFormTemplate(formTemplate){
            this.$emit('selectedFormTemplate', { id: this.id, formTemplate });
            this.formTemplateSelected = formTemplate ? formTemplate : null;
        },
        goToDetail() {
            return this.$router.push({ name: "competenceForm", params: { id : this.id } })
        },
        toggleChildrenVisibility() {
            this.$emit('toggle-visibility', this.id);
        }
    },
}