// a mixin for handling props for the different fields in forms
export const fieldMixin = {
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    isFieldCreation: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: '',
      required: false
    },
    structureIndex: {
      type: Number,
      required: false
    },
    fieldIndex: {
      type: Number,
      required: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    }
  },
  methods: {}
}