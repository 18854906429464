import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { API_URL } from "@/utils/constantes";

export default {
    setup() {

    },

    components: {

    },

    props: {
        isCreated: {
            default: true
        },
        organization: {
            default() {
                return {
                    id: null,
                    label: null,
                    place: null,
                    cover: null,
                }
            }
        }
    },

    watch: {
        dialog(val) {
            if (val && !this.isCreated && this.organization.id != null) {
                this.label = this.organization.label
                this.place = this.organization.place
                this.getDistantPath();
            }
        },
        coverImage(val) {
            if(val) {
                this.isUpdated = true
            } else {
                this.isUpdated = false
            }
        }
    },

    data() {
        return {
            valid: true,
            label: null,
            place: null,
            dialog: false,
            loading: false,
            isImport: null,
            importFile: null,
            coverImage: null,
            isUpdated: false,
            cover: null,
            rules: { required: value => !!value || 'Champs requis', },
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.dialog = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },

        async getDistantPath() {
            try {
                if(this.organization.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.organization.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.cover = objUrl;
            } catch (error) {
                this.cover = "";
            }
        },

        getFilePath(file) {
            return `${location.origin}/${file}`
        },

        preview() {
            return URL.createObjectURL(this.coverImage);
        },

        toggleImport() {
            this.isImport = !this.isImport;
            this.$refs.form.resetValidation();
        },

        async createUpdate() {
            this.loading = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = this.organization.id == null ? `/organizations` : `/organizations/${this.organization.id}`

                const formData = new FormData();
                formData.append("label", this.label)
                formData.append("place", this.place)
                if (this.coverImage != null) formData.append("fileData", this.coverImage);

                const request = await apiService.postFile(url, formData);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$emit("refresh", true);
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },

        async createFromTemplate() {
            this.loading = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const formData = new FormData();
                formData.append('importFile', this.importFile)
                const request = await apiService.postFile("/organizations/import", formData)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$emit("refresh", true);
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },

        actionHandler() {
            if (this.isCreated && this.isImport) this.createFromTemplate();
            if (this.isCreated && !this.isImport) this.createUpdate();
            if (!this.isCreated) this.createUpdate();
            return
        }
    },
}