import { fieldMixin } from "@/utils/fieldMixin";

export default {
  setup() { },
  mixins: [fieldMixin],
  data() {
    return {
      rules: {
        required: value => !!value || 'Ce champ est requis',
      },
      inputValue: ''
    }
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('TextAreaField', newValue);
      }
    }
  },
  
  created() {
    this.inputValue = this.field.value;
  },
  methods: {
    deleteField(index) {
      this.$emit('delete-field', index);
    },
    emitUpdateFieldValue() {
      this.$emit('update-field-value', {
        itemIndex: this.structureIndex,
        fieldIndex: this.fieldIndex,
        value: this.inputValue
      });
    },
    submiteEditedField(){
      this.field.value = this.inputValue

      this.$emit('edit-live-field', this.field, this.fieldIndex);
      this.$emit('edit-competence-field', this.field, this.fieldIndex, this.structureIndex);

    },
  }
}

