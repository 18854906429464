import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"

export default {
    setup() { },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        user: {
            default: null
        },
        showOnlyReferent: {
            default: false
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveAllOrganizations();
            if (val && this.user != null) {
                this.lastname = this.user.lastname
                this.firstname = this.user.firstname
                this.email = this.user.email
                this.selectedOrganization = this.user.organization
                this.role = this.user.role == "referent" ? { label: "Référent", value: "referent" } : { label: "Administrateur", value: "admin" }
            }
        },
    
    },

    data() {
        return {
            valid: true,
            loading: false,
            lastname: null,
            firstname: null,
            email: null,
            role: { label: "Référent", value: "referent" },
            roles: [{ label: "Administrateur", value: "admin" }, { label: "Référent", value: "referent" }],

            organizations: [],
            showOrganization: true,
            selectedOrganization: null,

            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
    },

    mounted() { },

    methods: {
        close() {
            this.$refs.form.reset();
            this.role = { label: "Référent", value: "referent" };
            this.$emit("update:dialog", false)
        },
        displayedLabel(item) {
            return `#${item.id} - ${item.label} - ${item.place}`
        },
        async retrieveAllOrganizations() {
            try {
                const request = await apiService.get(`/organizations?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.organizations = response.data

                if (this.user == null) {
                    this.selectedOrganization = response.data[0] ?? null;
                }
            } catch (error) {
                this.organizations = [];
            }
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = this.user == null ? `/priviledged/accounts` : `/priviledged/accounts/${this.user.id}`;

                const dataToSend =  { lastname: this.lastname, firstname: this.firstname, email: this.email, role: this.$route.params.id ? 'referent' : this.role.value, organization: this.$route.params.id ?? this.selectedOrganization.id }

                const request = await apiService.post(url, dataToSend)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$emit('refresh', true)
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}