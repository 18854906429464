import { EventBus } from "@/evt-bus";

export const structureFormValidationMixin = {
    methods: {
        parseData(data) {
            if (typeof data === 'string') {
                try {
                    return JSON.parse(data);
                } catch (error) {
                    console.error("Invalid JSON string:", error);
                    return data;
                }
            } else {
                return data;
            }
        },
        validateStructureFieldsValues(structure) {
            if (!Array.isArray(structure)) {
                throw new Error('Structure should be an array');
            }

            let isValid = true;
            structure.forEach(field => {
                if (field.required && (!field.value || field.value.trim() === '') ) {
                    this.$set(field, 'isError', true);
                    this.$set(field, 'message', 'Ce champ est requis.');
                    isValid = false;
                } else {
                    this.$set(field, 'isError', false);
                    this.$set(field, 'message', '');
                }
            });

            if (!isValid) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: `Veuillez compléter tous les champs requis`,
                    isError: true,
                });
            }

            return isValid;
        },
        validateStructureFieldsLabels(structure) {
            if (!Array.isArray(structure)) {
                throw new Error('Structure should be an array');
            }

            let isValid = true;
            structure.forEach(field => {
                if (!field.label) {
                    this.$set(field, 'isError', true);
                    this.$set(field, 'message', 'Ce champ est requis.');
                    isValid = false;
                } else {
                    this.$set(field, 'isError', false);
                    this.$set(field, 'message', '');
                }
            });

            if (!isValid) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: `Veuillez compléter tous les champs requis`,
                    isError: true,
                });
            }

            return isValid;
        },
        setRequiredLabelErrorMessage(fields, index, item) {
            if (!item.label) {
                this.$set(fields, index, {
                    ...fields[index],
                    isError: true,
                    message: "Ce champ est requis."
                });
            } else {
                this.$set(fields, index, {
                    ...fields[index],
                    isError: false,
                    message: ''
                });
            }
        },
        setRequiredValueErrorMessage(fields, index, item) {
            if (!item.value && item.required) {
                this.$set(fields, index, {
                    ...item,
                    isError: true,
                    message: "Ce champ est requis."
                });
            } else {
                this.$set(fields, index, {
                    ...item,
                    isError: false,
                    message: ''
                });
            }
        },
        checkFormFieldsValues() {
            return this.validateStructureFieldsValues(this.additionnalFields);
        },
        checkFormFieldsLabels() {
            return this.validateStructureFieldsLabels(this.additionnalFields) ;
        }
    }
};
