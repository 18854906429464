import TextField from '@/components/form/online/fields/TextField.vue';
import TextAreaField from '@/components/form/online/fields/TextAreaField.vue';
import SelectField from '@/components/form/online/fields/SelectField.vue';
import CheckboxGridField from '@/components/form/online/fields/CheckboxGridField.vue';
import StarRatingField from '@/components/form/online/fields/StarRatingField.vue';
import { structureFormValidationMixin } from "@/utils/structureFormValidationMixin";


// mixin for handling form functionnalities
export const formMixin = {
    components: {
        TextField,
        TextAreaField,
        SelectField,
        CheckboxGridField,
        StarRatingField,
    },
    mixins: [structureFormValidationMixin], 

    data() {
        return {
            loading: false,

            createFieldDialog: false,
            selectedFieldType: null,

            additionnalFields: [],

            formData: {},
            fieldToAdd: null,
            formTitle: '',
            formDescription: '',

            fieldOptions: [
                { text: '--', value: null },
                { text: 'Champ textuel simple', value: 'TextField' },
                { text: 'Champ textuel multiligne', value: 'TextAreaField' },
                { text: 'Liste déroulante', value: 'SelectField' },
                { text: 'Grille de case à cocher', value: 'CheckboxGrid' },
                { text: 'Notation par étoile', value: 'StarRatingField' }
            ],
            fieldTypeLabe: [
                { text: 'champ texte', value: 'TextField' },
                { text: 'champ de type texte area', value: 'TextAreaField' },
                { text: 'champ sélection', value: 'SelectField' },
                { text: 'grille de case', value: 'CheckboxGrid' },
                { text: 'notation par étoile', value: 'StarRatingField' }
            ],
            isArchived: false,
            hasErrors : []
        };
    },

    component: {
        TextField,
        TextAreaField,
        SelectField,
        CheckboxGridField,
        StarRatingField,
    },
    methods: {
        getComponentType(type) {
            switch (type) {
                case 'TextField':
                    return 'TextField';
                case 'TextAreaField':
                    return 'TextAreaField';
                case 'SelectField':
                    return 'SelectField';
                case 'CheckboxGrid':
                    return 'CheckboxGridField';
                case 'StarRatingField':
                    return 'StarRatingField';
                default:
                    return null;
            }
        },
        getFormData(formData) {
            this.formData = formData;
        },
        editLabelField(field, index) {
            this.$set(this.additionnalFields, index, field);
        },
        async editFieldValue(field, index) {
            this.$set(this.additionnalFields, index, field);
            await this.setRequiredValueErrorMessage(this.additionnalFields, index, field)
            this.additionnalFields = await this.updateFormStructure(this.additionnalFields);
        },
        async editCompetenceField(field, index, structureIndex) {
            let fields = this.parseData(this.groupedCompetences[structureIndex]);
            // Update the field at the specific index
            if (fields.structure){
                let structureField = this.parseData(fields.structure);
                this.$set(structureField, index, field);
                // Reflect the changes back to the fields.structure
                fields.structure = structureField;
                // Reflect the updated fields back to formCompetenceFields
                this.$set(this.groupedCompetences, structureIndex, fields);
                // Set any required value error message
                await this.setRequiredValueErrorMessage(structureField, index, field);
            }
        },
        async deleteField(index) {
            this.additionnalFields.splice(index, 1);
            this.$delete(this.formData, this.additionnalFields[index]);
        },
        moveFieldUp(index, fields) {
            if (index > 0) {
                const fieldToMove = fields.splice(index, 1)[0];
                fields.splice(index - 1, 0, fieldToMove);
                this.updateFormStructure(fields);
            }
        },
        moveFieldDown(index, fields) {
            if (index < fields.length - 1) {
                const fieldToMove = fields.splice(index, 1)[0];
                fields.splice(index + 1, 0, fieldToMove);
                this.updateFormStructure(fields);
            }
        },
        isFormLabelsValid() {
            return this.checkFormFieldsLabels()
        },
        isFormValuesValid() {
            return this.checkFormFieldsValues()
        },
        isFormCompetenceValuesValid(structure) {
            return this.validateStructureFieldsValues(structure)
        },
        async setFormStructure(newField) {
            let newForm = this.additionnalFields;
            let toBeCreateField = newField;
            newForm.push(toBeCreateField);
            this.additionnalFields = newForm.map((field, index) => {
                let fieldData = this.formData ? this.formData[field.label + index] : field;
                if (field.type === 'checkboxGrid') {
                    fieldData = {
                        rows: field.rows,
                        columns: field.columns,
                        isCheckbox: field.checkbox,
                        isRadiobox: field.radio,
                        values: field.value ? field.value : fieldData
                    };
                }
                return {
                    ...field,
                    value: field.value ? field.value : fieldData
                };
            });
        },
        async updateFormStructure(fields) {
            return fields.map((field, index) => {
                let currentField = field
                let fieldData = this.formData ? this.formData[currentField.label + index] : currentField;

                if (currentField.type === 'checkboxGrid') {
                    fieldData = {
                        rows: currentField.rows,
                        columns: currentField.columns,
                        values: currentField.value ? currentField.value : fieldData
                    };
                }

                return {
                    ...currentField,
                    value: currentField.value ? currentField.value : fieldData
                };
            });

        },
    }
};
