import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {
        currentSelected: {
            default() {
                return [];
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveSkillBases();
        },
        search(val) {
            const filtered = this.items.filter(el => el.label.includes(val));
            if (filtered.length == 0) {
                this.addForm = true;
            } else {
                this.addForm = false;
            }

            if (val == null || val.trim() == "") {
                this.addForm = false;
                this.showForm = false;
            } else {
                this.label = val
            }
        }
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            search: '',

            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label', },
                { text: 'Actions', value: 'actions' },
            ],
            items: [],

         

            addForm: false,
            showForm: false,
            rules: { required: value => !!value || 'Champs requis', },
        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        close() {
            if (this.addForm) {
                this.addForm = false;
                this.showForm = false;
                this.search = null;
                return;
            }
            this.dialog = false;
            this.$emit('canceled')
        },
        toogleSelected(item) {
            item.selected = !item.selected;
            const selected = this.items.filter(el => el.selected)
            this.$emit("selected", selected);

        },
        async retrieveSkillBases() {
            try {
                this.items = [];
                const request = await apiService.get(`/skill-bases`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;


                this.items.forEach(item => this.$set(item, 'selected', false));

                if(this.items.length == 0) {
                    this.addForm = true;
                }

                this.currentSelected.forEach(el => {
                    const current = this.items.find(item => item.id == el.id)
                    current.selected = true;
                })
            } catch (error) {
                this.items = [];
            }
        },
        customFilter(value, search) {
            const filtered = this.items.filter(el => el.label.includes(search));
            if (filtered.length == 0) this.addForm = true;
            else this.addForm = false;

            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
        },
        actionHandler() {
        
            const selected = this.items.filter(el => el.selected)
            this.$emit("selected", selected);
            this.$emit("confirmed", selected);
            if (this.addForm) {
                this.addForm = false;
                this.showForm = false;
                this.search = null;
                return;
            }
            this.dialog = false;
        },
      
    },
}