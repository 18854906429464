import LoginView from "@/views/offline/LoginView.vue";
import ForgottenView from "@/views/offline/ForgottenView.vue";
import ConfirmView from "@/views/offline/ConfirmView.vue";

const defaultRoutes = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/mot-de-passe-oublie",
        name: "forgottenAsk",
        component: ForgottenView,
    },
    {
        path: "/mot-de-passe-oublie/:token",
        name: "forgottenReset",
        component: ForgottenView,
    },
    {
        path: "/confirmation/:token",
        name: "confirmRegister",
        component: ConfirmView,
    },
]

export default defaultRoutes;