import { formMixin } from '@/utils/formMixin';

export default {
    props: {
      groupedCompetences: {
        default : null
      },
      role: String,
      items: Array,
      setTextClass: Function,
      setIcon: Function,
      isCompleted: Boolean,
      setSelectedEvalCompetence: Function,
      openReEvaluationDialog: Function,
      setEvaluationAction: Function,
      competenceTemplateFields: Array
    },
    mixins: [formMixin],
    watch: {
    
    },
    created(){
      this.panel = Array.from(Array(this.groupedCompetences.length).keys());
    },
    data() {
      return {
          creator: null,
          rules: { required: value => !!value || 'Champs requis', },
          loading: true,
          loadingBtn: false,
          defaultDisable: this.$route.params.id ? true : false,
          isEditing: this.$route.params.id ? true : false,
          evaluationId: this.$route.params.id ? true : false,
      }
  },
    methods: {
      // Generates a unique ID for each competence based on its label for the anchor
      generateId(label) {
        return label.replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '').toLowerCase();
      }
    }
  }