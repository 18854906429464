
export const utilMixin = {
  data() {
    return {

    }
  },
  methods: {
    parseData(data) {
      if (typeof data === 'string') {
        try {
          return JSON.parse(data);
        } catch (error) {
          console.error("Invalid JSON string:", error);
          return data; 
        }
      } else {
        return data;
      }
    },
 

  }
}