import CreateUpdatePriviledgeUserDialog from "@/components/dialog/CreateUpdatePriviledgeUserDialog.vue";
import PriviledgeUsersList from "@/components/list/administration/PriviledgeUsersList.vue"

export default {
    setup() { },

    components: {
        PriviledgeUsersList,
        CreateUpdatePriviledgeUserDialog,
    },

    props: {},

    watch: {

    },

    data() {
        return {
            createUpdateDialog: false,
            refreshBool: false,
            selectedItem: null,
        }
    },

    created() {

    },

    mounted() { },

    methods: {
        openCreateUpdateDialog(selected) {
            this.selectedItem = selected;
            this.createUpdateDialog = true;
        },
        refreshUsersList(val) {
            this.refreshBool = val
        }
    }
}