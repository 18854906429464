import { defineStore } from "pinia"

export const useAuthStore = defineStore("AuthStore", {
    state: () => {
        return {
            token: null,
            refreshToken: null,
            info: {
                id: null,
                lastname: null,
                firstname: null,
                role: null,
                email: null,
                organization: null,
            },
        }
    },

    getters: {},

    actions: {},

    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
                paths: [
                    "token",
                    "refreshToken",
                    "info",
                ]
            },
        ],
    }
})