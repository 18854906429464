import CompetenceList from "@/components/list/competence/CompetenceList.vue"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() { },

    components: {
        CompetenceList,
    },

    props: {},

    watch: {},

    data() {
        return {
            role: ''
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
    },

    mounted() { },

    methods: {
        goToCreate() {
            this.$router.push({ name: "competenceForm" })
        },
        goToModelList() {
            this.$router.push({ name: 'competenceModels' })
        }
    }
}