import Vue from 'vue'
import VueRouter from 'vue-router'
import defaultRoutes from './defaultRoutes';
import evaluationRoutes from './evaluationRoutes';
import organizationRoutes from './organizationRoutes';
import administrationRoutes from './administrationRoutes';
import competenceRoutes from './competenceRoutes';
import referenceLibraryRoutes from './referenceLibraryRoutes';
import skillBaseRoutes from './skillBasesRoutes';
import profilRoutes from './ProfilRoutes';
import generalRoutes from './generalRoutes';
import errorRoutes from './errorRoutes';
import { useAuthStore } from '@/stores/AuthStore';
import authTokenService from '@/services/authTokenService';

Vue.use(VueRouter)

let routes = [];

routes = routes.concat(
  defaultRoutes, profilRoutes, evaluationRoutes,
  organizationRoutes, competenceRoutes, skillBaseRoutes,
 generalRoutes, administrationRoutes, errorRoutes, referenceLibraryRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const { requiredAuth, roles } = to.meta;

  if (requiredAuth && !authTokenService.getToken()) {
    return next({ path: "/" });
  }

  if (requiredAuth && roles && !roles.includes(authStore.info.role)) {
    if(authStore.info.role == 'superadmin') return next({ path: "/organismes" });
    if(authStore.info.role == 'admin') return next({ path: "/organismes" });

    if(authStore.info.role == 'referent') return next({ path: "/organisme" });
    if(authStore.info.role == 'tutor') return next({ path: "/organisme" });
    if(authStore.info.role == 'learner') return next({ path: "/tableau-de-bord" });
  }

  next();
})

export default router