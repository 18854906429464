import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import CompetenceFormTemplateCard from "@/components/card/CompetenceFormTemplateCard.vue";
import DeleteCompetenceModelDialog from "@/components/dialog/DeleteCompetenceModelDialog.vue";


export default {
    setup() {

    },

    components: {
        DeleteCompetenceModelDialog,
        CompetenceFormTemplateCard
    },

    props: {

    },
    computed: {
        groupedItems() {
            return this.items.reduce((acc, item) => {
                const key = item.competence_id || 'autres'; // Use 'autres' for items without competence_id

                if (!acc[key]) {
                    acc[key] = {
                        title: key === 'autres' ? 'Non Assigné' : item.competence.label, // Title 'Autres' for no competence_id
                        data: []
                    };
                }
                acc[key].data.push(item);
                return acc;
            }, {});
        },
    },


    watch: {
        '$route'() {
            this.fetchCompetenceModels()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => { })
            this.$router.push({ name: this.$route.name, query: { search: val, filter: this.selectedFilter.value }, replace: true }).catch(() => { })
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search, filter: val.value };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => { })
        },
    },

    data() {
        return {
            loading: true,
            showTrash: false,
            items: [],
            filters: [
                { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
                { label: 'Trier par : Ordre alphabétique Z-A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
            total: 0,
            currentPage: 1,
            lastPage: 1,
            dialogCompetenceModelDelete: false,

            toDeleteId: 0,
        }
    },

    async created() {
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' };

        await this.fetchCompetenceModels();
    },

    mounted() {

    },

    methods: {
        openDeleteDialog(id) {
            this.toDeleteId = id
            this.dialogCompetenceModelDelete = true;
        },
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.fetchCompetenceModels();
        },
        changePage() {
            this.fetchCompetenceModels();
        },
        async fetchCompetenceModels() {
            this.loading = true
            try {
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                if (this.showTrash) queryString += '&target=trash'
                const url = `/forms?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;

                this.total = response.meta.total;
                this.lastPage = response.meta.last_page;
                this.currentPage = response.meta.current_page;
                // to get panels open by default 
                this.panel = Array.from(Array(this.items.length).keys());
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false
            }
        },
        async removeCompetenceModel() {
            try {
                const request = await apiService.delete(`/forms/${this.toDeleteId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                await this.fetchCompetenceModels();

            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    },
}