import { EventBus } from "@/evt-bus";

export default {
    props: {

    },

    components: {},

    watch: {},

    data() {
        return {
            snackbar: false,
            isError: false,
            text: '',
            timeout: 5000,
        }
    },

    created() { },

    mounted() {
        EventBus.$on("OPEN_SNACKBAR", this.setSnackbarConfig)
    },

    methods: {
        setSnackbarConfig({ message, isError }) {
            this.text = message;
            this.isError = isError;
            this.snackbar = true;
        }
    }
}