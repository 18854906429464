import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { useAuthStore } from "@/stores/AuthStore";
import { API_URL } from "@/utils/constantes";

export default {
    setup() { },

    components: {},

    props: {
        drawer: {
            default: null
        },
        evalCompetenceRow: {
            default: null
        },
    },

    watch: {
        drawer(val) {
            this.drawerData = val
            if (val) this.retrieveData();
        },
        drawerData(val) {
            this.$emit("drawerState", val)
        }
    },

    data() {
        return {
            valid: true,
            loading: true,
            drawerData: null,
            items: [],
            rules: { required: value => !!value || 'Champs requis', },
            loadingBtn: false,
            resourceData: null,
            selectedResourceType: { label: 'Lien', value: 1 },
            resourceTypes: [{ label: 'Lien', value: 1 }, { label: 'Fichier', value: 0 },],
            currentUserRole: '',
        }
    },

    created() {
        const authStore = useAuthStore();
        this.currentUserRole = authStore.info.role;
    },

    mounted() { },

    methods: {
        fetchResource(resource, type) {
            if (type == "PDF") return this.fetchRemoteResource(resource)
            if (type == "Image") return this.fetchRemoteResource(resource)
            if (type == "Vidéo") return this.fetchRemoteResource(resource)
            return resource;
        },
        async fetchRemoteResource(resource) {
            try {
                const request = await fetch(`${API_URL}/competence_resources/${resource}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resource;
                link.classList.add('d-none');
                link.click();
                window.URL.revokeObjectURL(link.href);
                link.remove();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async retrieveData() {
            this.loading = true;
            this.items = [];
            try {
                const authStore = useAuthStore();
                const request = await apiService.post(`/evaluations/competences/${this.evalCompetenceRow.competence.id}/resources?target=list`, {
                    organizationId: this.$route.params.organizationId ?? authStore.info.organization.id,
                    evaluationId: this.$route.params.evaluationId,
                    learnerId: this.$route.params.learnerId ?? authStore.info.id,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        async addResource() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) {
                    EventBus.$emit("OPEN_SNACKBAR", { message: "La ressource est requise", isError: true, });
                    return;
                }

                let targetResourceData;
                let request;

                if (this.selectedResourceType.value == 1) {
                    targetResourceData = this.resourceData
                    request = await apiService.post(`/evaluations/competences/${this.evalCompetenceRow.competence.id}/resources?target=add`, {
                        targetFile: targetResourceData,
                        evaluationCompetenceRow: this.evalCompetenceRow.id,
                    })
                } else {
                    targetResourceData = new FormData();
                    targetResourceData.append('targetFile', this.resourceData);
                    targetResourceData.append('evaluationCompetenceRow', this.evalCompetenceRow.id);
                    request = await apiService.postFile(`/evaluations/competences/${this.evalCompetenceRow.competence.id}/resources?target=add`, targetResourceData)
                }

                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items.unshift(response.data);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$refs.form.reset();
                this.selectedResourceType = { label: 'Lien', value: 1 };
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        }
    }
}