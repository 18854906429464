import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        learners: {
            default() {
                return []
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) this.fetchLearners()
        }
    },

    data() {
        return {
            loading: true,
            items: [],
            search: null,
            headers: [
                { text: 'Noms', align: 'start', sortable: false, value: 'lastname', },
                { text: 'Prénoms', value: 'firstname', },
                { text: 'Emails', value: 'email', },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        toogleSelection(item) {
            item.selected = !item.selected
        },
        async fetchLearners() {
            this.loading = true
            const authStore = useAuthStore();
            try {
                this.items = [];

                const url = (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') ? `/organizations/${this.learners[0].organization.id}/members?target=all&type=learners` : `/organizations/${authStore.info.organization.id}/members?target=all&type=learners`
                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.forEach(el => {
                    el.selected = false;
                })
                this.learners.forEach(el => {
                    let element = response.data.find(el2 => el2.id == el.id)
                    if (element) element.selected = true;
                })
                this.items = response.data;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false
            }
        },
        actionHandler() {
            const selectedItems = this.items.filter(el => el.selected)
            this.$emit('updateLearners', selectedItems)
            this.close();
        }
    },
}