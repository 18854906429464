export default {
    setup() { },

    components: {},

    props: {},

    watch: {
        '$route'() {
           this.setTitleRoute()
        }
    },

    data() {
        return {
            background: "height_default_bg",
            titlePage: { label: "Bienvenue sur AFEST" },
            titles: [
                { label: "Bienvenue sur AFEST" },
                { label: "Mot de passe oublié" },
                { label: "Confirmation d'inscription" },
            ]
        }
    },

    created() {
        this.setTitleRoute();
    },

    mounted() { },

    methods: {
        setTitleRoute() {
            const loginRoutes             = ["login"];
            const forgottenRoutes         = ["forgottenAsk", "forgottenReset"];
            const confirmRegisterRoutes   = ["confirmRegister"];
            let titlePageVariable  = this.titles[0];

            if(loginRoutes.includes(this.$route.name)) {
                titlePageVariable = this.titles[0]
            } else if(forgottenRoutes.includes(this.$route.name)) {
                titlePageVariable = this.titles[1]
            } else if(confirmRegisterRoutes.includes(this.$route.name)) {
                titlePageVariable = this.titles[2]
            } else {
                titlePageVariable = {label : "Oupss..."};
            }
            this.titlePage = titlePageVariable;
        }
    }
}