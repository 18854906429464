import CompetenceWithFormTemplateChoiceCard from "@/components/card/CompetenceWithFormTemplateChoiceCard.vue";
import DeleteCompetenceDialog from "@/components/dialog/DeleteCompetenceDialog.vue";

export default {
  components: {
    CompetenceWithFormTemplateChoiceCard,
    DeleteCompetenceDialog
  },
  props: {
    evaluationModel: {
      type: Object,
      required: false,
    },
    competences: {
      type: Array,
      required: true,
    },
    competenceTemplateFields: {
      type: Array,
      required: true,
    },
    defaultDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      childrenCompetenceTemplate: [],
      visibleCompetenceIds: [],
      toDeleteId: [],
      dialogCompetenceDelete :false,
      competencesWithRemoved: [],
      fields: []
    };
  },
  computed: {
    enabledChildrenCount() {
        return this.childrenCompetenceTemplate.reduce((count, item) => {
            if (item.is_parent == 1 && item.parent_id == 0 && item.children.length > 0) {
                return count + item.children.filter(child => child.is_enabled).length;
            }
            return count;
        }, 0);
    }
},
  watch: {
    evaluationModel: {
      handler(newVal) {
        if (newVal) {
          this.childrenCompetenceTemplate = newVal || [];
        }
      },
      immediate: true,
    },
    competences: {
      handler(newVal) {
        if (newVal) {
          this.childrenCompetenceTemplate = newVal;
        }
      },
      immediate: true,
    },
  },
  methods: {
    countEnabledChildren(parent) {
      return parent.children.filter(child => child.is_enabled).length;
    },
    getCompetenceFormFields(competenceId) {
      return this.competenceTemplateFields.filter(field => field.competence_id === competenceId).map(field => field);
    },
    isVisible(id) {
      return this.visibleCompetenceIds.includes(id);
    },
    toggleVisibility(id) {
      const index = this.visibleCompetenceIds.indexOf(id);
      if (index !== -1) {
        this.visibleCompetenceIds.splice(index, 1);
      } else {
        this.visibleCompetenceIds.push(id);
      }
    },
    forwardSelectedFormTemplate(payload) {
        this.$emit('selectedFormTemplate', payload);
      },
      openDeleteDialog(id) {
        this.toDeleteId = id
        this.dialogCompetenceDelete = true;
    },
    removeCompetence(){
      this.competencesWithRemoved = this.competences.filter(competence => competence.id !== this.toDeleteId && competence.parent_id !== this.toDeleteId);
      this.fields = this.competenceTemplateFields.filter(competence => competence.competence_id !== this.toDeleteId);
      this.$emit("saveCompetences", this.competencesWithRemoved, this.fields)
    
      this.toDeleteId = null;
      this.dialogCompetenceDelete = false;
    }
  },
};