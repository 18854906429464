export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: 0,
        },
        label: {
            default: '--'
        },
        description: {
            default: '--'
        },
        createdBy: {
            default: '--'
        },
        createdAt: {
            default: null,
        },
        competenceCount: {
            default: 0
        },
        isEnabled: {
            default: true,
        },
        showTrash: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {

        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        maxCharactere(desc) {
            if (desc.length > 80) desc = desc.slice(0, 80) + "..."
            return desc
        },
        goToDetail() {
            if(this.showTrash) {
                return this.$router.push({
                    name: 'evaluationForm',
                    params: { evaluationId: this.id, },
                    query: { archived: true }
                })
            }
            return this.$router.push({
                name: 'evaluationForm',
                params: { evaluationId: this.id, },
            })
        }
    },
}