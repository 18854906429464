import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import PasswordScore from "@/components/notifications/PasswordScore.vue";


export default {
    setup() { },

    components: {
        PasswordScore
    },

    props: {},

    watch: {},

    data() {
        return {
            valid: true,
            loading: false,
            lastname: null,
            firstname: null,
            email: null,
            oldPassword: null,
            newPassword: null,
            newPasswordConfirm: null,
            passwordScoreValid: false,

            showPassword1: false,
            showPassword2: false,
            showPassword3: false,

            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
        this.setDefaultValues()
    },

    mounted() { },

    methods: {
        setDefaultValues() {
            const authStore = useAuthStore();
            this.lastname = authStore.info.lastname
            this.firstname = authStore.info.firstname
            this.email = authStore.info.email
        },

        passwordScoreHandler({ score }) {
            if (score < 2) this.passwordScoreValid = false;
            else this.passwordScoreValid = true;
        },

        async udpdateProfil() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                if (this.oldPassword != null && this.oldPassword != "" && !this.passwordScoreValid) {
                    return EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Le nouveau mot de passe n'est pas robuste",
                        isError: true,
                    })
                }
                const dataSend = {
                    lastname: this.lastname,
                    firstname: this.firstname,
                    email: this.email,
                    oldPassword: this.oldPassword ?? "",
                    newPassword: this.newPassword ?? "",
                    newPasswordConfirm: this.newPasswordConfirm ?? "",
                }

                const request = await apiService.post("/profil", dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                if(this.oldPassword != null && this.newPassword != null && this.newPasswordConfirm != null) {
                    this.oldPassword = null;
                    this.newPassword = null;
                    this.newPasswordConfirm = null;
                }

                const authStore = useAuthStore();
                authStore.info = response.info;
                this.$refs.form.resetValidation();
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    }
}