import ForgottenAskForm from "@/components/form/offline/ForgottenAskForm.vue";
import ForgottenResetForm from "@/components/form/offline/ForgottenResetForm.vue";
import authTokenService from "@/services/authTokenService";

export default {
    setup() { },

    components: {
        ForgottenAskForm,
        ForgottenResetForm,
    },

    props: {},

    watch: {
        '$route'() {
            this.setFormTitle()
        }
    },

    data() {
        return {
            currentRoute: this.$route.name,
            formTitle: 'Renseignez votre email pour obtenir un lien de réinitialisation pour votre mot de passe'
        }
    },

    created() {
        if (authTokenService.getToken()) {
            return this.$router.push({ name: 'dashboard', replace: true });
        }
        this.setFormTitle();
    },

    mounted() { },

    methods: {
        setFormTitle() {
            this.currentRoute = this.$route.name;

            if (this.$route.name == "forgottenAsk") {
                this.formTitle = 'Renseignez votre email pour obtenir un lien de réinitialisation pour votre mot de passe'
            } else {
                this.formTitle = 'Mettez à jour votre mot de passe en renseignant un nouveau'
            }
        }
    }
}