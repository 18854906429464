export default {
    setup() { },

    components: {},

    props: {},

    watch: {},

    data() {
        return {
            links: [
                { url: "https://www.facebook.com/APRUNformation", icon: "/icons/facebook.png", titleIcon: "Facebook (nouvel onglet)" },
                { url: "https://re.linkedin.com/company/aprunformation", icon: "/icons/linkedin.png", titleIcon: "LinkedIn (nouvel onglet)" },
                { url: "https://twitter.com/aprunformation", icon: "/icons/twitter.png", titleIcon: "Twitter (nouvel onglet)" },
                { url: "https://www.instagram.com/aprunformation", icon: "/icons/instagram.png", titleIcon: "Instagram (nouvel onglet)" },
            ]
        }
    },

    created() { },

    mounted() { },

    methods: {
        getIcon(file) {
            return `${location.origin}/images/${file}`
        }
    }
}