import Vue from 'vue'
import GeneralLayout from './layouts/GeneralLayout.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import { createPinia, PiniaVuePlugin } from 'pinia'
import vueCompositionApi from '@vue/composition-api'
import piniaPersist from 'pinia-plugin-persist'

Vue.config.productionTip = false
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(piniaPersist)

Vue.use(vueCompositionApi)
Vue.use(pinia)

new Vue({
  vuetify,
  router,
  pinia,
  render: h => h(GeneralLayout)
}).$mount('#app')
