import RatingComponent from '@/components/form/online/fields/RatingComponent'
import { fieldMixin } from "@/utils/fieldMixin";

export default {
  components: {
    RatingComponent
  },

  mixins: [fieldMixin],

  data() {
    return {
      rules: {
        required: value => !!value || 'Ce champ est requis',
      },
      selectedValue: this.field.value
    };
  },
  watch: {

  },
  methods: {

    submiteEditedField(value){
      this.field.value = value
      this.$emit('edit-live-field', this.field, this.fieldIndex);
      this.$emit('edit-competence-field', this.field,  this.fieldIndex, this.structureIndex);

    },

  }
}
