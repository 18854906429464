import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import NotificationActionHandlerDialog from "@/components/dialog/NotificationActionHandlerDialog.vue"
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiEmailOpen } from '@mdi/js';

export default {
    setup() { },

    components: {
        NotificationActionHandlerDialog,
        SvgIcon,
    },

    props: {
        refreshItems: {
            default: false
        }
    },

    watch: {
        refreshItems(val) {
            if (val) this.retrieveList();
        }
    },

    data() {
        return {
            total: 0,
            lastPage: 1,
            currentPage: 1,
            mdiEmailOpen: mdiEmailOpen,

            items: [],
            loading: true,
            headers: [
                {
                    text: 'Le',
                    align: 'start',
                    filterable: false,
                    value: 'createdAt',
                },
                { text: 'Sujets', value: 'label' },
                { text: 'Descriptions', value: 'description' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],

            actionDialog: false,
            selectedItem: null,
            selectedActionType: "",
        }
    },

    created() {
        this.retrieveList();
        EventBus.$emit('updateReadCount')
    },

    mounted() { },

    methods: {
        changePage() {
            this.retrieveList();
        },

        refresh() {
            this.loading = true;
            this.items = [];
            this.total = 0;
            this.lastPage = 1;
            this.currentPage = 1;
            EventBus.$emit('updateReadCount')
            this.retrieveList();
        },

        resetValue() {
            this.actionDialog = false;
            this.selectedItem = null;
            this.selectedActionType = "";
        },

        setActionTypeToDialog(item, actionType) {
            this.actionDialog = true;
            this.selectedItem = item;
            this.selectedActionType = actionType;
        },

        goToElement(item) {
            if (!item.isRead) {
                this.selectedItem = item;
                this.toggleRead();
            }

            const authStore = useAuthStore();
            if (authStore.info.role == 'tutor') {
                return this.$router.push({
                    name: 'singleEvaluationDetail',
                    params: { evaluationId: item.actionValue, learnerId: item.customData },
                    replace: true,
                });
            }

            if (authStore.info.role == 'learner') {
                return this.$router.push({
                    name: 'singleEvaluation',
                    params: { evaluationId: item.actionValue },
                    replace: true,
                });
            }
        },

        async retrieveList() {
            this.loading = true;
            try {
                const url = this.currentPage != 1 ? `/notifications?page=${this.currentPage}` : `/notifications`
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = []
            } finally {
                this.loading = false;
            }
        },

        async toggleRead() {
            try {
                const request = await apiService.put(`/notifications/${this.selectedItem.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                let elementToUpdate = this.items.find(el => this.selectedItem.id == el.id)
                if (elementToUpdate) elementToUpdate.isRead = true;
                this.resetValue();
                EventBus.$emit('updateReadCount')
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async deleteOne() {
            try {
                const request = await apiService.delete(`/notifications/${this.selectedItem.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                EventBus.$emit('updateReadCount')
                this.retrieveList();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}