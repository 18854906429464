import PriviledgeUsers from "@/views/online/administration/PriviledgeUsers.vue";

const administrationRoutes = [
    {
        path: "/administrations",
        name: "administrations",
        component: PriviledgeUsers,
        meta: { requiredAuth: true, roles: ["superadmin", "admin"] }
    },
]

export default administrationRoutes;