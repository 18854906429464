import { useAuthStore } from "@/stores/AuthStore"

export default {
    props: {
    },

    components: {

    },

    watch: {

    },

    data() {
        return {
            loading: false,
            currentItem: 0,
            role: '',
            items: [
                {
                    icon: 'mdi-account-group',
                    text: 'Apprenant',
                    isMaterialDesign: true,
                    identifier: "learners",
                },
                {
                    icon: 'mdi-file-sign',
                    text: 'Evaluations',
                    isMaterialDesign: true,
                    identifier: "evaluations",
                },
                {
                    icon: "mdi-chart-areaspline",
                    text: 'Statistiques',
                    isMaterialDesign: true,
                    identifier: "stats",
                },
                {
                    icon: "mdi-package-variant-closed",
                    text: 'Référentiels',
                    isMaterialDesign: true,
                    identifier: "skillBases",
                },
            ],
        }
    },

    created() { 
        const authStore = useAuthStore();
        this.role = authStore.info.role
    },

    mounted() {

    },

    methods: {
        goBack() {
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') {
                return this.$router.push({
                    name: "singleOrganization",
                    replace: true,
                    params: {
                        id: this.$route.params.organizationId
                    }
                })
            }

            if (authStore.info.role == 'tutor' || authStore.info.role == 'referent') {
                return this.$router.push({
                    name: "generalOrganization",
                    replace: true,
                })
            }

            return;
        },
        goToSection({ identifier }) {
            const element = document.getElementById(identifier)
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
}