import { fieldMixin } from "@/utils/fieldMixin";

export default {
  setup() {},
  mixins: [fieldMixin],
  data() {
    return {
      rules: {
        required: value => !!value || 'Ce champ est requis',
      },
      inputValue: '',

    }
  },
 
  created() {
    this.inputValue = this.field.value;
  },
  methods: {
    emitUpdateFieldValue() {
      this.$emit('update-field-value', {
        itemIndex: this.structureIndex,
        fieldIndex: this.fieldIndex,
        value: this.inputValue
      });
    },
    async submiteEditedField() {
      this.field.value = this.inputValue
      await this.$emit('edit-live-field', this.field, this.fieldIndex);
      await this.$emit('edit-competence-field', this.field, this.fieldIndex, this.structureIndex);

    },
    deleteField(index) {
      this.$emit('delete-field', index);
    },
  }
}