import EvaluationProfilView from "@/views/online/evaluations/EvaluationProfilView.vue";
import SingleEvaluationView from "@/views/online/evaluations/SingleEvaluationView.vue";
import EvaluationFormView from "@/views/online/evaluations/EvaluationFormView.vue";
import EvaluationsView from "@/views/online/evaluations/EvaluationsView.vue";
import EvaluationModelView from "@/views/online/evaluations/EvaluationModelView.vue";
import EvaluationModelFormView from "@/views/online/evaluations/EvaluationModelFormView.vue";
import EvaluationLearnerFormView from "@/views/online/evaluations/EvaluationLearnerFormView.vue";
import EvaluationLearnerFormViewForTutor from "@/views/online/evaluations/EvaluationLearnerFormViewForTutor.vue";

const evaluationRoutes = [
    {
        path: "/organismes/:organizationId/evaluations/apprenants/:learnerId",
        name: "singleEvaluationProfil",
        component: EvaluationProfilView,
        meta: { requiredAuth: true, roles: ["superadmin","admin"] }
    },
    {
        path: "/organismes/:organizationId/evaluations/:evaluationId/apprenants/:learnerId",
        name: "evaluationDetail",
        component: SingleEvaluationView,
        meta: { requiredAuth: true, roles: ["superadmin","admin"] }
    },
    {
        path: "/tableau-de-bord",
        name: "dashboard",
        component: EvaluationProfilView,
        meta: { requiredAuth: true, roles: ["learner", "superadmin"] }
    },
    {
        path: "/evaluations",
        name: "evaluations",
        component: EvaluationsView,
        meta: { requiredAuth: true, roles: ["superadmin","tutor", "admin"] }
    },
    {
        path: "/evaluations/formulaire/:evaluationId?",
        name: "evaluationForm",
        component: EvaluationFormView,
        meta: { requiredAuth: true, roles: ["superadmin","tutor", "admin"] }
    },
    {
        path: "/evaluations/:evaluationId",
        name: "singleEvaluation",
        component: SingleEvaluationView,
        meta: { requiredAuth: true, roles: ["learner", "superadmin", "admin"] }
    },
    {
        path: "/evaluations/apprenants/:learnerId",
        name: "evaluationProfil",
        component: EvaluationProfilView,
        meta: { requiredAuth: true, roles: ["tutor", "referent", "superadmin", "admin"] }
    },
    {
        path: "/evaluations/:evaluationId/apprenants/:learnerId",
        name: "singleEvaluationDetail",
        component: SingleEvaluationView,
        meta: { requiredAuth: true, roles: ["tutor", "referent", "superadmin", "admin"] }
    },
    {
        path: "/evaluations/modeles",
        name: "evaluationModels",
        component: EvaluationModelView,
        meta: { requiredAuth: true, roles: ["tutor", "referent", "superadmin", "admin"] }
    },
    {
        path: "/evaluations/modele/:id?",
        name: "evaluationModelForm",
        component: EvaluationModelFormView,
        meta: { requiredAuth: true, roles: [ "tutor", "referent", "superadmin", "admin"] }
    },
    {
        path: "/evaluations/:evaluationId/modele/:evaluationModelId/formulaire/apprenants/:learnerId",
        name: "evaluationLearnerFormViewForTutor",
        component: EvaluationLearnerFormViewForTutor,
        meta: { requiredAuth: true, roles: ["tutor", "referent", "superadmin","admin"] }
    },
    {
        path: "/evaluations/:evaluationId/modele/:evaluationModelId/formulaire/apprenants/:learnerId",
        name: "evaluationLearnerFormView",
        component: EvaluationLearnerFormView,
        meta: { requiredAuth: true, roles: ["learner", "superadmin", "admin"] }
    },
   
]

export default evaluationRoutes;