import OrganizationView from "@/views/online/organization/OrganizationView.vue";
import SingleOrganizationView from "@/views/online/organization/SingleOrganizationView.vue";
import SingleArchivedOrganizationView from "@/views/online/organization/SingleArchivedOrganizationView.vue";

const organizationRoutes = [
    {
        path: "/organismes",
        name: "organizations",
        component: OrganizationView,
        meta: { requiredAuth: true, roles: ["superadmin","admin"] }
    },
    {
        path: "/organismes/:id",
        name: "singleOrganization",
        component: SingleOrganizationView,
        meta: { requiredAuth: true, roles: ["superadmin","admin"] }
    },
    {
        path: "/organismes/:id/archives",
        name: "singleArchivedOrganization",
        component: SingleArchivedOrganizationView,
        meta: { requiredAuth: true, roles: ["superadmin","admin"] }
    },
]

export default organizationRoutes;