

export const evaluationFormMixin = {
    data() {
        return {
       

        };
    },
    methods: {
        // Détermine l'évaluation basée sur le rôle
        getEvaluationsByRole() {
            if (this.$route.query.source == "learner"){
                return (this.role === "learner") ?  this.evalByTutor : this.evalByUser ;
            }else if (this.$route.query.source == "tutor"){
                return (this.role === "learner") ?  this.evalByTutor : this.evalByUser ;
            }
            return (this.role === "learner") ? this.evalByUser : this.evalByTutor;

        },

        // Obtient les évaluations associées aux competenceTemplateFields
        getAssociatedEvaluations(evalByRole) {
            return this.competenceTemplateFields
                .filter(item => evalByRole.find(comp => comp.competence.id === item.competence_id))
                .map(item => {
                    let evaluation = evalByRole.find(comp => comp.competence.id === item.competence_id);
                    return {
                        ...item,
                        evaluation: evaluation
                    };
                });
        },

        // Obtient les évaluations non associées aux competenceTemplateFields
        getUnassociatedEvaluations(evalByRole, associatedEvaluations) {
            const associatedCompetenceIds = new Set(associatedEvaluations.map(item => item.competence_id));
            return evalByRole
                .filter(comp => !associatedCompetenceIds.has(comp.competence.id))
                .map(comp => ({ evaluation: comp }));
        },

        // Combine les évaluations associées et non associées en une seule liste
        getAllEvaluations() {
            const evalByRole = this.getEvaluationsByRole();
            const associatedEvaluations = this.getAssociatedEvaluations(evalByRole);
            const unassociatedEvaluations = this.getUnassociatedEvaluations(evalByRole, associatedEvaluations);
            return [
                ...associatedEvaluations,
                ...unassociatedEvaluations
            ];
        },

        // Associe les compétences correspondantes aux évaluations
        associateCompetences(allEvaluations) {
            return allEvaluations.map(item => {
                let competence = this.competences.find(comp => comp.id === item.evaluation.competence.id);
                return {
                    ...item,
                    competence: competence || { label: 'No Label' },
                    parentCompetenceId: competence.parent_id != 0 ?  competence.parent_id : competence.id
                };
            });
        },

        // Organise les compétences par parent
        organizeByParent(allCompetences) {
            return allCompetences.reduce((acc, item) => {
                let parentId = item.parentCompetenceId;
            
                if (!acc[parentId]) {
                    acc[parentId] = [];
                }
                acc[parentId].push(item);
                return acc;
            }, {});
        },

        // Crée une liste ordonnée avec parents suivis de leurs enfants
        createOrderedList(parentChildMap) {
            const orderedList = [];
            for (const parentId in parentChildMap) {
                const parentAndChildren = parentChildMap[parentId];
                const parent = parentAndChildren.find(item => item.competence.id === parentId);
                if (parent) {
                    orderedList.push(parent);
                }
                const children = parentAndChildren.filter(item => item.parent_id !== parentId);
                orderedList.push(...children);
            }
            return orderedList;
        },


        // Fonction principale pour regrouper les compétences par parent
        groupCompetencesByParent() {
            const allEvaluations = this.getAllEvaluations();
            const associatedCompetences = this.associateCompetences(allEvaluations);
            const parentChildMap = this.organizeByParent(associatedCompetences);
            this.groupedCompetences = this.createOrderedList(parentChildMap);
        },

        getCompetenceLabel(parentId) {
            const parentCompetence = this.competences.find(comp => comp.id === parentId);
            return parentCompetence ? parentCompetence.label : 'No Parent';
        },
    }
};
