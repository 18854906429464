import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {},

    props: {},

    watch: {},

    data() {
        return {
            valid: true,
            loading: false,
            showPassword: false,
            email: null,
            password: null,
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {

    },

    mounted() { },

    methods: {
        goToForgotten() {
            this.$router.push({ name: "forgottenAsk" })
        },
        async login() {
            this.loading = true;

            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const request = await apiService.post("/login", { email: this.email, password: this.password });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const authStore = useAuthStore()
                authStore.token = response.token;
                authStore.refreshToken = response.refreshToken;
                authStore.info = response.info;
                authTokenService.setToken(response.token)

                this.$refs.form.reset()
                this.$refs.form.resetValidation();
                this.$emit("authentication", true)
                
                if(response.info.role == "superadmin") return this.$router.push({ name: "organizations", replace: true })
                if(response.info.role == "admin") return this.$router.push({ name: "organizations", replace: true })
                if(response.info.role == "referent") return this.$router.push({ name: "generalOrganization", replace: true })
                if(response.info.role == "tutor") return this.$router.push({ name: "generalOrganization", replace: true })
                if(response.info.role == "learner") return this.$router.push({ name: "dashboard", replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    }
}