
import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import authTokenService from "@/services/authTokenService";
import { useAuthStore } from "@/stores/AuthStore";
import { API_URL } from "@/utils/constantes";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveAllLearners();
        }
    },

    data() {
        return {
            items: [],
            search: null,
            loading: false,
            loadingLearners: true,

            headers: [
                { text: 'Noms', align: 'start', sortable: false, value: 'lastname', },
                { text: 'Prénoms', value: 'firstname', },
                { text: 'Emails', value: 'email', },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.search = null;
            this.items = []
            this.$emit("update:dialog", false)
        },

        toogleSelection(selected) {
            const element = this.items.find(el => el.id == selected.id)
            element.selected = !element.selected;
        },

        async retrieveAllLearners() {
            this.loadingLearners = true;
            const authStore = useAuthStore();

            try {
                this.items = [];
                const request = await apiService.get(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=all&type=learners`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                response.data.forEach(element => {
                    element.selected = false;
                });

                this.items = response.data;
            } catch (error) {
                this.items = [];
            } finally {
                this.loadingLearners = false;
            }
        },

        async actionHandler() {
            this.loading = true
            const authStore = useAuthStore();

            try {
                const selected = [];
                const selectedUsers = this.items.filter(el => el.selected);
                selectedUsers.forEach(element => {
                    selected.push(element.id);
                });

                const request = await fetch(`${API_URL}/exports?target=monitoring`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                    body: JSON.stringify({ users: selected, organizationId: this.$route.params.id ?? authStore.info.organization.id, })
                })
                if (!request.ok) throw new Error("Une erreur est survenue");

                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = `rapport_suivi_${new Date().getTime()}`;
                link.classList.add('d-none');
                link.click();
                window.URL.revokeObjectURL(link.href); 
                link.remove();
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}