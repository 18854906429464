<template>
  <div class="rating-container">
    <v-row>

      <div v-for="idx in totalRange" :key="idx" class="mdi-icon mr-3" @click="selectRating(idx)">
        <v-icon :class="getIconClass(idx)">{{ getIconName(idx) }}</v-icon>
      </div>
    </v-row>
  </div>
</template>

<script>
const range = (start, end, length = end - start + 1) =>
  Array.from({ length }, (_, i) => start + i)

export default {
  props: {
    value: {
      required: false
    },
    total: {
      type: Number,
      required: true
    },
    isDisable:{
      type: Boolean,
    }
  },
  data() {
    return {
      completeRange: [],
      halfRange: [],
      totalRange: [],
      lastValue: 0
    }
  },
  created() {
    if (this.value > this.total) {
      throw new Error('total lower than value')
    }
    this.updateRanges()
  },
  watch: {
    value() {
      this.updateRanges()
    },
    total() {
      this.updateRanges()
    }
  },
  methods: {
    updateRanges() {
      this.totalRange = range(1, this.total);

    },
    selectRating(rating) {
      if (this.isDisable){
        this.$emit('input', rating)
      }
    },
    getIconClass(idx) {
      return idx <= this.value ? 'selected-star' : '';
    },
    getIconName(idx) {

      return idx <= this.value ? 'mdi-star' : 'mdi-star-outline';

    }
  }
}
</script>

<style scoped>
.rating-container {
  display: flex;
  justify-content: center;
}

.selected-star {
  color: gold;
}
</style>
