import { useAuthStore } from "@/stores/AuthStore";
import EvalCompetenceDialog from '@/components/dialog/EvalCompetenceDialog.vue';
import ReEvaluateCompetenceDialog from '@/components/dialog/ReEvaluateCompetenceDialog.vue';
import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {
        EvalCompetenceDialog,
        ReEvaluateCompetenceDialog,
    },


    props: {
        title: {
            default: ""
        },
        generalComment: {
            default: ''
        },
        validCompetence: {
            default: 0
        },
        totalCompetence: {
            default: 0
        },
        competenceEvals: {
            default() {
                return [];
            }
        },
        globalCommentEnable: {
            default: true
        },
        isLeft: {
            default: false
        },
        evaluationInfo : {
            default: {}
        }
    },

    watch: {

    },

    data() {
        return {
            reEvaluateDialog: false,
            evalDialog: false,
            selectedEvalCompetence: null,
            role: '',
            items: [
                { title: 'Voir les commentaires', action: 'showComments', icon: 'mdi-message-reply-text', isLeft: true },
                { title: 'Voir les ressources', action: 'showResources', icon: 'mdi-folder-outline', isLeft: true },
                { title: 'Historique', action: 'showHistory', icon: 'mdi-folder-outline' },
            ],
            competencesToEvals: [],
            validCompetenceCount: 0,

            valid: true,
            loadingBtn: false,
            displayedComment: '',
            currentHistory: null,
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.competencesToEvals = this.competenceEvals
        this.validCompetenceCount = this.validCompetence
        this.displayedComment = this.generalComment
    },

    mounted() {

    },

    methods: {
        setChipColor() {
            const half = Math.ceil(+this.totalCompetence / 2);
            if (+this.validCompetenceCount >= half) {
                return 'green lighten-3';
            } else {
                return 'red lighten-3';
            }
        },
        setBorderColor(status) {
            const borders = {
                "Non évalué": "border: 1px solid blue !important; background-color: white !important;",
                "Acquis": "border: 1px solid green !important;",
                "Partiel": "border: 1px solid orange !important;",
                "Non acquis": "border: 1px solid red !important;",
                "--": "",
            }
            return borders[status];
        },
        setTextClass(status) {
            const classes = {
                "Non évalué": "blue--text",
                "Acquis": "green--text",
                "Partiel": "orange--text",
                "Non acquis": "red--text",
                "--": "black--text",
            }
            return classes[status];
        },
        setIcon(status) {
            const icons = {
                "Non évalué": "",
                "Acquis": "mdi-check-circle-outline",
                "Partiel": "",
                "Non acquis": "mdi-close-circle-outline",
                "--": "",
            }
            return icons[status];
        },
        setSelectedEvalCompetence(item) {
            const anchor = item.competence.label.replace(/[^a-z0-9]+/g, '-')  // Remplace les caractères non-alphanumériques par des tirets
            .replace(/^-+|-+$/g, '').toLowerCase();
            localStorage.setItem('navigationAnchor', anchor);
            if (this.evaluationInfo && this.evaluationInfo != null) {
             
                    this.$router.push({ name: "evaluationLearnerFormView", 
                        params: { 
                           evaluationId: this.evaluationInfo.evaluation.id, 
                            learnerId: this.evaluationInfo.learner.id, 
                            evaluationModelId: this.evaluationInfo.evaluationModel.id,
                         } });
                
            }
        },
        actionHandler(item, competence) {
            this.$emit(item.action, competence)
        },
        refreshData({ currentData, currentEval, competences }) {
            const targetEl = this.competencesToEvals.find(el => el.id == this.selectedEvalCompetence.id);
            targetEl.status = currentData.status;
            targetEl.history = currentData.history;
            this.validCompetenceCount = this.competencesToEvals.filter(el => el.status == "Acquis").length
            this.$emit('refreshData', { currentEval, competences })
        },
        async setGeneralComment() {
            this.loadingBtn = true
            try {
                const request = await apiService.post(`/evaluations/responses?target=comment`, {
                    userEval: this.evaluationInfo.id,
                    comment: this.displayedComment,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false });
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        openReEvaluationDialog(item) {
            this.reEvaluateDialog = true
            this.selectedEvalCompetence = item;
        },
        resetValue() {
            this.selectedEvalCompetence = null;
        },
        setEnableValue({currentData, currentEval, competences}) {
            let updated = this.competencesToEvals.find(el => el.id == this.selectedEvalCompetence.id)
            updated.isEnable = true;
            updated.status = "Non évalué";
            updated.history = currentData.history
            this.$emit('refreshData', { currentEval, competences })
        }
    },
}