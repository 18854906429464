import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {

    },

    watch: {

    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            importFile: null,
            rules: { required: value => !!value || 'Champs requis', },
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.dialog = false;
            this.$refs.form.reset();
        },
        getFilePath(file) {
            return `${location.origin}/${file}`
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const formData = new FormData();
                formData.append('importFile', this.importFile)
                const request = await apiService.postFile(`/competences/import`, formData)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
                this.close();
                this.$router.push({ name: "competences", replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}