import { fieldMixin } from "@/utils/fieldMixin";

export default {
  mixins: [fieldMixin],
  data() {
    return {
      isEdited: false,
      rules: {
        required: value => !!value || 'Ce champ est requis',
      },
      newOptionText: '',
      newOptionValue: '',
    }
  },
  computed: {
    fieldValue: {
      get() {
        return this.field;
      },

    }
  },
  created() {},
  mounted() {
  },
  methods: {
    updateSelectedValue(newValue) {
      this.$emit('update-field-value', { itemIndex: this.structureIndex, fieldIndex: this.fieldIndex, value: newValue });
    },
    addOption() {
      if (!this.field.options) {
        this.$set(this.field, 'options', []);
      }

      if (!Array.isArray(this.field.options)) {
        this.$set(this.field, 'options', [{
          text: '',
          value: '',
        }]);
      } else {
        this.$set(this.field.options, this.field.options.length, { text: '', value: '' });
      }
    },
    submiteEditedField(value) {
      this.field.value = value
      this.$emit('edit-live-field', this.field, this.fieldIndex);
      this.$emit('edit-competence-field', this.field, this.fieldIndex, this.structureIndex);

    },
    removeOption(index) {
      this.field.options.splice(index, 1);
    },
    deleteField(index) {
      this.$emit('delete-field', index);
    },
  }
};