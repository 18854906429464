import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() { },

    components: {},

    props: {},

    watch: {},

    data() {
        return {
            valid: true,
            loading: false,
            email: null,
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() { },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({ name: "login", replace: true })
        },
        async forgottenHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const request = await apiService.post("/forgotten?target=ask", { email: this.email });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.$refs.form.reset()
                this.$refs.form.resetValidation();
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    }
}