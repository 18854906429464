import { useAuthStore } from "@/stores/AuthStore";
import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignCompetencesToEvaluationDialog from "@/components/dialog/AssignCompetencesToEvaluationDialog.vue"
import EvalCompetenceDialog from '@/components/dialog/EvalCompetenceDialog.vue';
import ReEvaluateCompetenceDialog from '@/components/dialog/ReEvaluateCompetenceDialog.vue';
import CreateFormTemplateFieldDialog from "@/components/dialog/CreateFormTemplateFieldDialog.vue";
import CompetenceWithFormTemplateChoiceCard from "@/components/card/CompetenceWithFormTemplateChoiceCard.vue";
import EvaluationCompetencesListLearner from "@/components/list/evaluation/EvaluationCompetencesListLearner.vue"
import EvaluationCompetencesListTutor from "@/components/list/evaluation/EvaluationCompetencesListTutor.vue"
import CommentsDrawer from "@/components/drawer/CommentsDrawer.vue"
import ResourcesDrawer from "@/components/drawer/ResourcesDrawer.vue"
import UserEvalResponseHistoriesDrawer from "@/components/drawer/UserEvalResponseHistoriesDrawer.vue"
import CompetenceForm from "@/components/form/online/evaluation/CompetenceForm.vue";
import CompetenceTreeCards from "@/components/card/CompetenceTreeCards.vue";

import { evaluationFormMixin } from "@/utils/evaluationFormMixin";
import { formMixin } from '@/utils/formMixin';
import { evaluationMixin } from '@/utils/evaluationMixin';
import { utilMixin } from '@/utils/utilMixin';

export default {
    setup() { },

    components: {
        RestoreOrDeleteDialog,
        AssignCompetencesToEvaluationDialog,
        EvalCompetenceDialog,
        ReEvaluateCompetenceDialog,
        CreateFormTemplateFieldDialog,
        CompetenceWithFormTemplateChoiceCard,
        EvaluationCompetencesListLearner,
        EvaluationCompetencesListTutor,
        CommentsDrawer,
        ResourcesDrawer,
        UserEvalResponseHistoriesDrawer,
        CompetenceForm,
        CompetenceTreeCards
    },
    mixins: [formMixin, evaluationMixin, evaluationFormMixin, utilMixin],

    props: {},

    watch: {
        '$route'() {
            this.$router.go(0)
        },
    },

    data() {
        return {
            role: '',
            creator: null,
            rules: { required: value => !!value || 'Champs requis', },

            isFormPreview: false,
            loading: true,
            loadingBtn: false,
            assignLearnersDialog: false,
            assignCompetencesDialog: false,

            label: null,
            description: null,
            isEnabled: true,
            valid: true,
            isArchived: false,
            isPersonnalization: false,

            skillBase: {},
            skillBasesList: [],
            competences: [],

            learner: null,
            learners: [],
            learnerCompetences: [],

            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            evaluationId: this.$route.params.id ? true : false,

            competenceTemplateFields: [],
            learnerCompetenceFields: [],
            formCompetenceFields: [],
            openEvaluationConfirmDialog: false,
            groupedCompetences: {},
            localPanel: [],
            parentCompetence: 0,
            competenceToEvaluationCount: 0,
            openSendEvaluationConfirmDialog: false,

        }
    },

    async created() {
        if (this.$route.query.source === 'personnalized') {
            this.isPersonnalization = true
        }

        const authStore = useAuthStore();
        this.role = authStore.info.role;
        await this.fetchSingleEvaluation();

     
    },

    mounted() {
        const anchor = localStorage.getItem('navigationAnchor');
        if (anchor != null) {
            this.$nextTick(() => {
                setTimeout(() => {
                    const element = document.querySelector(`#${anchor}`);

                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                        localStorage.removeItem('navigationAnchor');
                    } else {
                        console.error('Element not found:', anchor);
                    }
                }, 800);
            });
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        toggleFormPreview() {
            this.isFormPreview = !this.isFormPreview;
        },
        updateCompetences(data) {
            this.competences = data;
        },
        setSelectedEvalCompetence(item) {
            this.evalDialog = true;
            this.selectedEvalCompetence = item;
        },
        setAdditionEvaluationFields(evaluationFields) {
            this.additionnalFields = []
            JSON.parse(evaluationFields).forEach(async field => {
                await this.setFormStructure(field);
            });
        },
        async refreshEvaluationData({ currentEval, competences }) {
            this.item = currentEval;
            this.evalByTutor = competences.filter(el => !el.isSelf);
            this.evalByUser = competences.filter(el => el.isSelf);
            this.validCompetenceByTutor   = this.evalByTutor.filter(el => el.status == "Acquis" && el.competence.is_parent).length
            // refresh view display without reloading
            this.groupCompetencesByParent();

        },
        openSendConfirmDialog() {
            this.openSendEvaluationConfirmDialog = true;
        },
        openConfirmDialog() {
            this.openEvaluationConfirmDialog = true;
        },
        close() {
            this.openEvaluationConfirmDialog = false;
        },
         updateCompetenceRemoving(competences, competenceTemplateFields) {
             this.competences = competences;
             this.competenceTemplateFields  = competenceTemplateFields;
       },
        addFormTemplateToCompetence( formTemplate ) {
            if (formTemplate.formTemplate != null){
                const index = this.competenceTemplateFields.findIndex(el => el.competence_id === formTemplate.formTemplate[0].competence_id);
                if (index !== -1) {
                    this.$set(this.competenceTemplateFields, index, formTemplate.formTemplate[0]);
                } else {
                    this.competenceTemplateFields.push(formTemplate.formTemplate[0])
                }
            }else{
                const index = this.competenceTemplateFields.findIndex(el => el.competence_id === formTemplate.id);
                if (index !== -1) { // Check if the element is found
                    this.competenceTemplateFields.splice(index, 1); // Remove the element
                  }
            }
            
       
        },
        async fetchSkillBases() {
            this.loading = true;
            try {
                const request = await apiService.get(`/skill-bases?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.skillBasesList = response.data;
            } catch (error) {
                this.skillBasesList = [];
            } finally {
                this.loading = false;
            }
        },
        setEvaluationSourceByUser(response){
            if (this.$route.query.source == "learner"){
                this.validCompetenceByTutor = this.validCompetenceByLearner 
                return response.data
            } 
            this.validCompetenceByLearner = this.validCompetenceByTutor 
            return this.tutorEval.evaluationModel 
        },
        async fetchSingleEvaluation() {
            this.loading = true;
            try {
                await this.fetchEvaluationInfo();
                let target = ""
                let template = null
                if (this.$route.query.archived) target = "?target=trash"

                const request = await apiService.get(`/evaluation-models/${this.$route.params.evaluationModelId}${target}`);

                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.label = response.data.label;
                this.isEnabled = response.data.isEnabled;
                this.learners = [];
                this.competences = [];
                this.skillBase = response.data.skillBase;
                this.skillBasesList = [this.skillBase];
                this.competences = response.data.competences;
                this.isEditing = this.completed_by_learner
                this.parentCompetence = this.competences.filter(item => item.is_parent)
                this.competenceToEvaluationCount = this.competences.filter(el => el.is_parent).length;

                template = this.setEvaluationSourceByUser(response)

                if (this.isPersonnalization) {
                    template = response.data
                    this.setAdditionEvaluationFields(template.evaluationFields);
                    JSON.parse(template.competencesFields).forEach(el => {
                        this.competenceTemplateFields.push(el)
                    })

                } else {
                    this.competenceTemplateFields = this.parseData(template.competencesFields);
                    this.localPanel = Array.from(Array(this.competences.length).keys());
                    this.groupCompetencesByParent();
                    this.setAdditionEvaluationFields(template.evaluationFields);
                }
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async sendCompletedEvaluation() {
            this.loadingBtn = true;
            try {
                this.openSendEvaluationConfirmDialog = false;
                this.valid = this.checkFormFieldsValues();
                if (!this.valid) return;

                let allFieldsValid = true;
                this.groupedCompetences.forEach((fields, structureIndex) => {
                    if (fields.structure && !this.validateStructureFieldsValues(this.parseData(fields.structure))) {
                        allFieldsValid = false;
                        this.$set(this.groupedCompetences, structureIndex, fields);

                    } else {
                        // Reflect validated fields back to formCompetenceFields to update the view
                        this.$set(this.groupedCompetences, structureIndex, fields);
                    }
                });

                // Validate structure fields
                if (!allFieldsValid) return;

                const competencesToSend = this.competences.map(el => el.id);
                const url = `/user-evaluation/completed`;
                const request = await apiService.post(url, {
                    competences: competencesToSend,
                    learner_id: this.$route.params.learnerId,
                    evaluation_id: this.$route.params.evaluationId,
                    evaluation_model_id: this.$route.params.evaluationModelId,
                    structure_fields_evaluation: this.additionnalFields,
                    structure_fields_competence: this.groupedCompetences,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false });
                this.defaultDisable = true;
                this.goBack();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                });
            } finally {
                this.loadingBtn = false;
            }
        },
        async actionHandler() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                if (this.competences.length == 0){
                    EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Les compétences sont requises",
                        isError: true,
                    })
                    return;
                }

               const competencesToSend = this.competences.map(el => el.id);
                let childrenToSend = this.competences.map(el => el.children).flat();
                let childrenToSendIds = childrenToSend.map(el => el.id)
                competencesToSend.push(... childrenToSendIds)
                const uniqueIds = new Set([...competencesToSend, ...childrenToSendIds]);
                // Convert the Set back to an array if needed
                const uniqueIdsArray = Array.from(uniqueIds);

                const url = `/user-evaluation`
                const request = await apiService.post(url, {
                    label: this.label,
                    description: this.description,
                    skill_base_id: this.skillBase.id,
                    competences: uniqueIdsArray,
                    learner_id: this.$route.params.learnerId,
                    evaluation_id: this.$route.params.evaluationId,
                    evaluation_model_id: this.$route.params.evaluationModelId,
                    structure_fields_evaluation: this.additionnalFields,
                    structure_fields_competence: this.competenceTemplateFields
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, });
                this.close();
                this.goBack();

            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        },
    }

}