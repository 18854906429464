import { useAuthStore } from '@/stores/AuthStore';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiAccountSchool, mdiAccountTie } from '@mdi/js';

export default {
    props: {
    },

    components: {
        SvgIcon
    },

    watch: {

    },

    data() {
        return {
            currentUserRole: '',
            currentItem: 0,
            items: [
                {
                    icon: 'mdi-town-hall',
                    text: 'Organisme',
                    isMaterialDesign: true,
                    identifier: "organization",
                },
                {
                    icon: mdiAccountTie,
                    text: 'Tuteurs',
                    isMaterialDesign: false,
                    identifier: "tutors",
                },
                {
                    icon: mdiAccountSchool,
                    text: 'Apprenants',
                    isMaterialDesign: false,
                    identifier: "learners",
                },
            ],
        }
    },

    created() {
        const authStore = useAuthStore();
        this.currentUserRole = authStore.info.role;
        if (this.currentUserRole == 'admin' || this.currentUserRole == 'superadmin') {
            this.items = [
                {
                    icon: 'mdi-town-hall',
                    text: 'Organisme',
                    isMaterialDesign: true,
                    identifier: "organization",
                },
                {
                    icon: mdiAccountTie,
                    text: 'Référents',
                    isMaterialDesign: false,
                    identifier: "referents",
                },
                {
                    icon: mdiAccountTie,
                    text: 'Tuteurs',
                    isMaterialDesign: false,
                    identifier: "tutors",
                },
                {
                    icon: mdiAccountSchool,
                    text: 'Apprenants',
                    isMaterialDesign: false,
                    identifier: "learners",
                },
            ];
        }
        if (this.currentUserRole == 'tutor') {
            this.items = [
                {
                    icon: 'mdi-town-hall',
                    text: 'Organisme',
                    isMaterialDesign: true,
                    identifier: "organization",
                },
                {
                    icon: mdiAccountSchool,
                    text: 'Apprenants',
                    isMaterialDesign: false,
                    identifier: "learners",
                },
            ];
        }
    },

    mounted() {

    },

    methods: {
        goBack() {
            if (this.currentUserRole != 'admin' && this.currentUserRole != 'superadmin') return;
            this.$router.push({ name: 'organizations', replace: true })
        },
        goToSection({ identifier }) {
            const element = document.getElementById(identifier)
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
}