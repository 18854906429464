import SkillsBasesView from "@/views/online/skills_bases/SkillsBasesView.vue";
import SkillBasesForm from "@/views/online/skills_bases/SkillBasesForm.vue";

const skillBaseRoutes = [
    {
        path: "/referentiels",
        name: "skillsBases",
        component: SkillsBasesView,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
    {
        path: "/referentiels/formulaire/:id?",
        name: "skillsBasesForm",
        component: SkillBasesForm,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
]

export default skillBaseRoutes;