import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false,
        },
        currentTutor: {
            default: null
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveSkillBases()
        }
    },

    data() {
        return {
            loading: false,
            items: [],
            role: '',
            search: '',
            headers: [
                { text: '', value: 'detail' },
                { text: 'Libellés', align: 'start', sortable: false, value: 'label', },
                { text: 'Etats', value: 'isEnabled', },
                { text: 'Compétences', value: 'competenceCount', },
            ],
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
    },

    mounted() {

    },

    methods: {
        close() {
            this.search = '';
            this.items = []
            this.$emit("update:dialog", false)
        },
        toogleSelection(selected) {
            if (this.role == 'referent') return;
            const element = this.items.find(el => el.id == selected.id)
            element.selected = !element.selected;
        },
        skillBaseDetail(item) {
            return this.$router.push({ name: "skillsBasesForm", params: { id: item.id } })
        },
        async retrieveSkillBases() {
            this.items = []
            try {
                const request = await apiService.get(`/skill-bases?target=all&tutor=${this.currentTutor.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                response.data.forEach(element => {
                    element.selected = false;
                });

                if (this.currentTutor.skillBases) {
                    this.currentTutor.skillBases.forEach(el => {
                        const updated = response.data.find(el2 => el2.id == el.id)
                        updated.selected = true;
                    })
                }

                response.data.sort(function (a) {
                    return !a.selected;
                });

                if (this.role == 'referent') {
                    response.data = response.data.filter(el => el.selected)
                }

                this.items = response.data;
            } catch (error) {
                this.items = []
            }
        },
    },
}