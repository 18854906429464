import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: 0,
        },
        label: {
            default: '--'
        },
        description: {
            default: '--'
        },
        createdBy: {
            default: '--'
        },
        isEnabled: {
            default: true,
        },
        competenceCount: {
            default: 0
        }
    },

    watch: {

    },

    data() {
        return {

        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        maxCharactere(desc) {
            if (desc.length > 80) desc = desc.slice(0, 80) + "..."
            return desc
        },
        goToDetail() {
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') {
                this.$router.push({
                    name: 'evaluationDetail',
                    params: {
                        organizationId: this.$route.params.organizationId,
                        evaluationId: this.id,
                        learnerId: this.$route.params.learnerId,
                    },
                })
            } else if (authStore.info.role == 'learner') {
                this.$router.push({
                    name: 'singleEvaluation',
                    params: { evaluationId: this.id, },
                })
            } else {
                this.$router.push({
                    name: 'singleEvaluationDetail',
                    params: {
                        evaluationId: this.id,
                        learnerId: this.$route.params.learnerId,
                    },
                })
            }
        }
    },
}