import HeaderComponent from "@/components/general/HeaderComponent.vue"

export default {
    components: {
        HeaderComponent,  
    },

    props: {},

    watch: {},
    
    data() {
        return {};
    },

    created() { },

    mounted() { },

    methods: {
        authentication(payload) {
            this.$emit('authentication', payload)
        }
    },
};