export default {
    components: {
        
    },

    props: {},

    watch: {},
    
    data() {
        return {};
    },

    created() { },

    mounted() { },

    methods: {},
};