import apiService from "@/services/apiService"
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

import OrganizationTutors from "@/components/list/organization/OrganizationTutors.vue";
import OrganizationLearners from "@/components/list/organization/OrganizationLearners.vue";
import OrganizationReferents from "@/components/list/organization/OrganizationReferents.vue";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue";
import OrganizationSideLeftNav from "@/components/navigation/OrganizationSideLeftNav.vue";
import { API_URL } from "@/utils/constantes";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {

    },

    components: {
        OrganizationTutors,
        OrganizationLearners,
        RestoreOrDeleteDialog,
        OrganizationSideLeftNav,
        OrganizationReferents,
    },

    props: {

    },

    watch: {},

    data() {
        return {
            loading: true,
            role: '',
            cover: null,
            item: { id: null, label: "", learnerCount: 0, isArchived: false }
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role
        this.retrieveSingleOrganization();
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.push({name: "organizations", replace: true})
        },
        async getDistantPath() {
            try {
                if(this.item.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.item.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.cover = objUrl;
            } catch (error) {
                this.cover = "";
            }
        },
        async retrieveSingleOrganization() {
            this.loading = true;
            const authStore = useAuthStore();

            try {
                const request = await apiService.get(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}?target=trash`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.item = response.data;
                this.getDistantPath();
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },

        refreshComponent() {
            this.retrieveSingleOrganization();
        },

        async restoreOrganization() {
            const authStore = useAuthStore();

            try {
                const request = await apiService.put(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$router.push({ name: "singleOrganization", replace: true, params: { id: this.$route.params.id ?? authStore.info.organization.id } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async hardDeleteOrganization() {
            const authStore = useAuthStore();

            try {
                const request = await apiService.delete(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.goBack();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        }
    }
}