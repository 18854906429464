import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {

    },

    props: {
        currentSelected: {
            default() {
                return [];
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveCategories();
        },
        search(val) {
            if (val != null) {
                this.search = val.charAt(0).toUpperCase() + val.slice(1);
            } else {
                this.addForm = false
            }
        }
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            addForm: false,
            search: '',
            confirmDelete: false,
            role: '',

            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label', },
                { text: 'Actions', value: 'actions' },
            ],
            categories: [],
            selectedItem: null,
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
    },

    mounted() {

    },

    methods: {
        close() {
            if (this.confirmDelete) {
                this.confirmDelete = false;
                return;
            }

            this.selectedItem = null;
            this.dialog = false;
            this.$emit('canceled')
        },
        toogleSelected(item) {
            item.selected = !item.selected;
            const selected = this.categories.filter(el => el.selected)
            this.$emit("selected", selected);
        },
        customFilter(value, search) {
            const filtered = this.categories.filter(el => el.label.includes(search));
            if (filtered.length == 0) this.addForm = true;
            else this.addForm = false;

            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
        },
        async retrieveCategories() {
            try {
                const request = await apiService.get(`/categories`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.categories = response.data;
                if (this.categories.length == 0) this.addForm = true;
                else this.addForm = false;

                this.currentSelected.forEach(el => {
                    const current = this.categories.find(item => item.id == el.id)
                    current.selected = true;
                })
            } catch (error) {
                this.categories = [];
            }
        },
        async createCategory() {
            try {
                const request = await apiService.post(`/categories`, { label: this.search })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.retrieveCategories();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        actionHandler() {
            if (this.confirmDelete) {
                this.deleteCategory(this.selectedItem)
                return;
            }
            const selected = this.categories.filter(el => el.selected)
            this.$emit("selected", selected);
            this.$emit("confirmed", selected);
            if (this.confirmDelete) {
                this.confirmDelete = false;
                return;
            }

            this.selectedItem = null;
            this.dialog = false;
        },
        async deleteCategory(item) {
            try {
                if (!this.confirmDelete) {
                    this.confirmDelete = true
                    this.selectedItem = item;
                    return;
                }
                const request = await apiService.delete(`/categories/${item.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.categories = this.categories.filter(el => el.id != item.id)
                if (this.categories.length == 0) this.addForm = true;
                else this.addForm = false;
                const selected = this.categories.filter(el => el.selected)
                this.$emit("selected", selected);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.confirmDelete = false
                this.selectedItem = null;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        }
    },
}