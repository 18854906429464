import UserInfoCard from "@/components/card/UserInfoCard.vue";
import UpdateUserDialog from "@/components/dialog/UpdateUserDialog.vue"
import DisableUserDialog from "@/components/dialog/DisableUserDialog.vue"
import RestoreOrDeleteUserDialog from "@/components/dialog/RestoreOrDeleteUserDialog.vue"
import CreateUpdatePriviledgeUserDialog from "@/components/dialog/CreateUpdatePriviledgeUserDialog.vue";

import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {
        UserInfoCard,
        UpdateUserDialog,
        DisableUserDialog,
        RestoreOrDeleteUserDialog,
        CreateUpdatePriviledgeUserDialog,
    },

    props: {
        isArchived: {
            default: false,
        },
        isRefreshTutors: {
            default: false,
        }
    },

    watch: {
        search(val) {
            if(val == null || val.trim() == "") this.retrieveReferents();
            if (this.loading) return;
            this.retrieveReferents();
        },
        updateUserDialog(val) {
            if (!val) {
                this.selectedUser = { id: null, lastname: null, firstname: null, email: null };
            }
        },
    },

    data() {
        return {
            total: 0,
            items: [],
            lastPage: 1,
            search: null,
            currentPage: 1,
            loading: true,
            updateUserDialog: false,
            disableUserDialog: false,
            restoreUserDialog: false,
            isRestoring: false,
            showTrash: false,
            selectedUser: null,
        }
    },

    created() {
        EventBus.$on('refreshReferent', () => {
            this.retrieveReferents();
        })

        this.retrieveReferents();
    },

    mounted() {
    },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveReferents();
        },
        async refreshComponent() {
            await this.retrieveReferents();
        },
        async retrieveReferents() {
            this.loading = true;
            try {
                const authStore = useAuthStore();

                let target = 'untrash'
                if(this.showTrash) target = "trash"
                const url = (this.search == null || this.search.trim() == "")
                    ? `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=referents&page=${this.currentPage}`
                    : `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=referents&search=${this.search}&page=${this.currentPage}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        changePage() {
            this.retrieveReferents();
        },
        createUser() {
            this.updateUserDialog = true;
            this.selectedUser = null;
        },
        updateUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.updateUserDialog = true;
        },
        disableUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.disableUserDialog = true;
        },
        restoreUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = true;
        },
        deleteUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = false;
        },
    },
}