import ReferenceLibraryView from "@/views/online/reference_library/ReferenceLibraryView.vue";
import ReferenceLibraryForm from "@/views/online/reference_library/ReferenceLibraryForm.vue";

const referenceLibraryRoutes = [
    {
        path: "/reference-libraries",
        name: "referenceLibraries",
        component: ReferenceLibraryView,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
    {
        path: "/reference-libraries/formulaire/:id?",
        name: "referenceLibrariesForm",
        component: ReferenceLibraryForm,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
]

export default referenceLibraryRoutes;