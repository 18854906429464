import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {
        currentSelected: {
            default() {
                return [];
            }
        },
        competenceId: {
            default(){
                return 0;
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveFormTemplates();
        },
        search(val) {
            const filtered = this.items.filter(el => el.label.includes(val));
            if (filtered.length == 0) {
                this.addForm = true;
            } else {
                this.addForm = false;
            }

            if (val == null || val.trim() == "") {
                this.addForm = false;
                this.showForm = false;
            } else {
                this.label = val
            }
        }
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            search: '',

            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label', },
                { text: 'Actions', value: 'actions' },
            ],
            items: [],

            label: null,
            evaluationCriteria: null,
            categories: [],

            addForm: true,
            showForm: false,
            rules: { required: value => !!value || 'Champs requis', },
        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit('canceled')
            this.dialog = false;
         
        },
        toogleSelected(item) {
            this.$set(item, 'selected', !item.selected);
            const selected = this.items.filter(el => el.selected)
            this.$emit("selected", selected);

        },
        async retrieveFormTemplates() {
            try {
                this.items = [];
                const request = await apiService.get(`/forms?target=assignation`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items = response.data
                // get only form templates associated to a competence and only template without competence assignation
                this.items = this.items.filter(item => item.is_validated && (item.competence_id == this.$route.params.id || item.competence_id == null))
                if (this.items.length == 0) {
                    this.addForm = true;
                }

                this.currentSelected.forEach(el => {
                    const current = this.items.find(item => item.id === el.id);
                    this.$set(current, 'selected', true);
                });

                this.items.sort((a, b) => {
                    if (a.selected && !b.selected) return -1;
                    if (!a.selected && b.selected) return 1;
                    return 0;
                });
            } catch (error) {
                this.items = [];
            }
        },
        customFilter(value, search) {
            const filtered = this.items.filter(el => el.label.includes(search));
            if (filtered.length == 0) this.addForm = true;
            else this.addForm = false;

            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
        },
        actionHandler() {
            const selected = this.items.filter(el => el.selected)
            this.dialog = false;
            this.$emit("confirmed", selected);

            if (this.addForm) {
                this.addForm = false;
                this.showForm = false;
                this.search = null;
                return;
            }
        },
        goToCreateCompetenceFormTemplateView() {
            const url = this.$router.resolve({ name: "competenceFormTemplate", params: { competenceId : this.competenceId} }).href;
            window.open(url, '_blank');
        },
    },
}