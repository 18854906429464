import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import PasswordScore from "@/components/notifications/PasswordScore.vue";


export default {
    setup() { },

    components: {
        PasswordScore
    },

    props: {},

    watch: {},

    data() {
        return {
            valid: true,
            loading: false,
            password: null,
            confirmPassword: null,
            passwordScoreValid: false,

            showPassword1: false,
            showPassword2: false,

            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
    },

    mounted() { },

    methods: {
        passwordScoreHandler({ score }) {
            if (score < 2) this.passwordScoreValid = false;
            else this.passwordScoreValid = true;
        },

        goToLogin() {
            this.$router.push({ name: "login", replace: true })
        },

        async confirmAccount() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                if (!this.passwordScoreValid) {
                    return EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Le nouveau mot de passe n'est pas robuste",
                        isError: true,
                    })
                }

                const dataSend = {
                    confirmToken: this.$route.params.token,
                    password: this.password,
                    passwordConfirm: this.confirmPassword,
                }

                const request = await apiService.post("/confirm", dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.goToLogin();
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    }
}