import ProfilView from "@/views/online/general/ProfilView.vue";

const profilRoutes = [
    {
        path: "/profil",
        name: "profil",
        component: ProfilView,
        meta: { requiredAuth: true }
    },
]

export default profilRoutes;