import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() { },

    components: {

    },

    props: {
    
    },

    watch: {
    
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            lastname: null,
            firstname: null,
            email: null,
            user: null,
            role: "tutor",
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
        const authStore = useAuthStore();
        this.user = authStore.info;
    },

    mounted() { },

    methods: {
        close() {
            this.$refs.form.reset();
            this.dialog = false;
            this.$emit('canceled')
        },
        displayedLabel(item) {
            return `#${item.id} - ${item.label} - ${item.place}`
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = `/priviledged/accounts`;
                const dataToSend =  { lastname: this.lastname, firstname: this.firstname, email: this.email, role: "tutor", organization: this.user.organization.id }
                const request = await apiService.post(url, dataToSend)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$emit('refreshTutors')
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}