import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() { },

    components: {},

    props: {
        drawer: {
            default: null
        },
        evalCompetenceRow: {
            default: null
        },
    },

    watch: {
        drawer(val) {
            this.drawerData = val
            if (val) this.retrieveData();
        },
        drawerData(val) {
            this.$emit("drawerState", val)
        }
    },

    data() {
        return {
            valid: true,
            loading: true,
            drawerData: null,
            items: [],
            rules: { required: value => !!value || 'Champs requis', },
            comment: null,
            loadingBtn: false,
            currentUserRole: '',
        }
    },

    created() {
        const authStore = useAuthStore();
        this.currentUserRole = authStore.info.role;
    },

    mounted() { },

    methods: {
        async retrieveData() {
            this.loading = true;
            this.items = [];
            try {
                const authStore = useAuthStore();
                const request = await apiService.post(`/evaluations/competences/${this.evalCompetenceRow.competence.id}/comments?target=list`, {
                    organizationId: this.$route.params.organizationId ?? authStore.info.organization.id,
                    evaluationId: this.$route.params.evaluationId,
                    learnerId: this.$route.params.learnerId ?? authStore.info.id,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        async addComment() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) {
                    EventBus.$emit("OPEN_SNACKBAR", { message: "Le commentaire est requis", isError: true, });
                    return;
                }
                const request = await apiService.post(`/evaluations/competences/${this.evalCompetenceRow.competence.id}/comments?target=add`, {
                    content: this.comment,
                    evaluationCompetenceRow: this.evalCompetenceRow.id,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items.unshift(response.data);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$refs.form.reset();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        }
    }
}