import authTokenService from "@/services/authTokenService"
import { API_URL } from "@/utils/constantes"

export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: null,
        },
        label: {
            default: "",
        },
        place: {
            default: "",
        },
        learnerCount: {
            default: null
        },
        isArchived: {
            default: false
        },
        cover: {
            default: null
        }
    },

    watch: {

    },

    data() {
        return {
            coverImage: null,
        }
    },

    created() {
        this.getDistantPath();
    },

    mounted() {

    },

    methods: {
        goToDetail() {
            // for managed organization id when user role is super admin which is not rattached to an organization
            if (this.id){
                localStorage.setItem('organizationId',  this.id);
            }

            if(this.isArchived) return this.$router.push({ name: "singleArchivedOrganization", params: { id: this.id } })
                
            this.$router.push({ name: "singleOrganization", params: { id: this.id } })
        },
        async getDistantPath() {
            try {
                if(this.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.coverImage = objUrl;
            } catch (error) {
                this.coverImage = null;
            }
        },
    },
}