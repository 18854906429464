import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import EvaluationSideLeftNav from "@/components/navigation/EvaluationSideLeftNav.vue";
import EvaluationProfilList from "@/components/list/evaluation/EvaluationProfilList.vue";
import LearnerStats from "@/components/stats/LearnerStats.vue";
import { API_URL } from "@/utils/constantes";
import authTokenService from "@/services/authTokenService";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() { },

    components: {
        EvaluationSideLeftNav,
        EvaluationProfilList,
        LearnerStats,
    },

    props: {},

    watch: {
        search() {
            this.retrieveEvaluationsFromFolder();
        },
        currentPage() {
            this.retrieveEvaluationsFromFolder();
        }
    },

    data() {
        return {
            loading: true,
            loadingBtn: false,
            reportDialog: false,
            search: null,
            skillBaseCount: 0,
            item: null,
            displaySkillBases: [],
            displayEvaluations: [],
            totalPage: 0,
            currentPage: 1,
            cover: null,
        }
    },

    created() {
        this.retrieveProfil();
    },

    mounted() { },

    methods: {
        goBack() {
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') {
                return this.$router.push({
                    name: "singleOrganization",
                    replace: true,
                    params: {
                        id: this.$route.params.organizationId
                    }
                })
            }

            if (authStore.info.role == 'tutor' || authStore.info.role == 'referent') {
                return this.$router.push({
                    name: "generalOrganization",
                    replace: true,
                })
            }

            return;
        },

        toggleActive(item) {
            this.displaySkillBases.forEach(el => {
                el.active = false;
            })
            item.active = true;
        },

        async getDistantPath() {
            try {
                if(this.item.organization.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.item.organization.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.cover = objUrl;
            } catch (error) {
                this.cover = "";
            }
        },

        async retrieveEvaluationFolderInfo() {
            try {
                const authStore = useAuthStore();
                const request = await apiService.get(`/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/evaluations/learners/${this.$route.params.learnerId ?? authStore.info.id}?target=info`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.item = response.data;
                this.getDistantPath();
            } catch (error) {
                throw new Error(error);
            }
        },

        async retrieveEvaluationsFromFolder() {
            try {
                const authStore = useAuthStore();
                const searchQuery = (this.search != null && this.search.trim() != "") ? `&search=${this.search}` : ``
                const request = await apiService.get(`/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/evaluations/learners/${this.$route.params.learnerId ?? authStore.info.id}?target=evaluations${searchQuery}&page=${this.currentPage}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.displayEvaluations = response.data.filter(el => el.evaluation != null && el.evaluation.createdBy != null);
                this.currentPage = response.current_page;
                this.totalPage = response.last_page;
            } catch (error) {
                throw new Error(error);
            }
        },

        async retrieveSkillBaseFromFolder() {
            try {
                const authStore = useAuthStore();
                const request = await apiService.get(`/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/evaluations/learners/${this.$route.params.learnerId ?? authStore.info.id}?target=skillbases`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const skillBases = [];
                response.data.forEach(el => {
                    if(el.evaluation) {
                        skillBases.push(el.evaluation.skillBase)
                    }
                })

                this.displaySkillBases = [];
                const uniqueSkillBases = [...new Set(skillBases.map(data => data.id))];

                uniqueSkillBases.forEach(el => {
                    const element = skillBases.find(skillBase => skillBase.id == el);
                    element.active = false;
                    this.displaySkillBases.push(element)
                })

                this.skillBaseCount = this.displaySkillBases.length;

                if (this.displaySkillBases.length > 0) {
                    this.displaySkillBases[0].active = true;
                }
            } catch (error) {
                throw new Error(error);
            }
        },

        async retrieveProfil() {
            this.loading = true;
            try {
                await this.retrieveEvaluationFolderInfo();
                await this.retrieveEvaluationsFromFolder();
                await this.retrieveSkillBaseFromFolder();
            } catch (error) {
                this.goBack();
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },

        async generateOverviewReport() {
            this.loadingBtn = true;
            try {
                const authStore = useAuthStore();
                const request = await fetch(`${API_URL}/exports?target=overview`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                    body: JSON.stringify({
                        userId: this.$route.params.learnerId ? this.$route.params.learnerId : authStore.info.id,
                        organizationId: this.$route.params.organizationId ? this.$route.params.organizationId : authStore.info.organization.id,
                    })
                })
                if (!request.ok) throw new Error("Une erreur est survenue");

                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);

                let titlePart = `${this.item.lastname} ${this.item.firstname}`;
                titlePart = titlePart.replaceAll('.', '').replaceAll(' ', '_');

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = `rapport_synthetique_${titlePart}_${new Date().getTime()}`;
                link.classList.add('d-none');
                link.click();
                window.URL.revokeObjectURL(link.href); 
                link.remove();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }

        }
    }
}