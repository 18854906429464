export default {
    setup() {

    },

    components: {

    },

    props: {
        title: {
            default: '- - -'
        },
        total: {
            default: 0
        },
        validCount: {
            default: 0
        },
    },

    watch: {

    },

    data() {
        return {
            progress: 0
        }
    },

    created() {
        this.progress =  (this.validCount / this.total) * 100
    },

    mounted() {

    },

    methods: {

    },
}