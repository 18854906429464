import ErrorView from "@/views/error/ErrorView.vue";

const errorRoutes = [
    {
        path: "*",
        name: "notFound",
        component: ErrorView,
    }
]

export default errorRoutes;