import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() { },

    components: {},

    props: {
        competenceId: {
            default: 0
        },
        drawer: {
            default: false
        },
        formTemplateId: {
            default: null
        }
    },

    watch: {
        drawer(val) {
            this.drawerState = val;
            if (val) this.retrieveData();
        },

    },

    data() {
        return {
            valid: true,
            loading: true,
            drawerState: this.drawer,
            items: [],
            rules: { required: value => !!value || 'Champs requis', },
            comment: null,
            loadingBtn: false,
            currentUserRole: '',
            currentUser: null,
            editDrawer: false,
            editingItemId: null,
            editedContent: '',
            deleteDialog: false,
            itemToDelete: null,
        }
    },

    created() {
        const authStore = useAuthStore();
        this.currentUser = authStore.info;

    },

    mounted() { },

    methods: {
        toggleEdit(itemId) {


            if (this.editingItemId === itemId) {
                this.cancelEdit();
            } else {
                const item = this.items.find(item => item.id === itemId);
                this.editingItemId = itemId;
                this.editedContent = item.content;
            }
        },
        async saveEdit(item) {
            item.content = this.editedContent;
            await this.updateComment(this.editingItemId);
            this.editingItemId = null;
            this.editedContent = '';
        },
        cancelEdit() {
            this.editingItemId = null;
            this.editedContent = '';
        },
        async confirmDelete() {
            // Appeler la fonction de suppression avec l'ID de l'élément
            this.toggleDelete(this.itemToDelete);
            await this.deleteComment();
            this.deleteDialog = false;
        },
        toggleDelete(itemId) {
            this.itemToDelete = itemId;
            this.deleteDialog = true;
        },
        async retrieveData() {
            this.loading = true;
            this.items = [];
            try {
                let url = `/competences/${this.competenceId}/comments?target=list`

                if (this.formTemplateId != null) {
                    url = `/competences/form/${this.formTemplateId}/comments?target=list`
                }
                const request = await apiService.get(url)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        setComment(comment, isAddedComment) {
            // Ajouter l'élément au début du tableau
            !isAddedComment ? this.items.push(comment) : this.items.unshift(comment);

            // Supprimer les doublons en utilisant un objet Map
            const uniqueCommentMap = new Map();
            this.items.forEach(item => {
                uniqueCommentMap.set(item.id, item);
            });

            // Convertir l'objet Map en tableau
            this.items = Array.from(uniqueCommentMap.values());
        },
        async addComment() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) {
                    EventBus.$emit("OPEN_SNACKBAR", { message: "Le commentaire est requis", isError: true, });
                    return;
                }

                let url = `/competences/${this.competenceId}/comments?target=add`

                if (this.formTemplateId != null) {
                    url = `/competences/form/${this.formTemplateId}/comments?target=add`
                }
                const request = await apiService.post(url, {
                    content: this.comment,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.setComment(response.data, true);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$refs.form.reset();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        },
        async updateComment(id) {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) {
                    EventBus.$emit("OPEN_SNACKBAR", { message: "Le commentaire est requis", isError: true, });
                    return;
                }
                const request = await apiService.post(`/competences/${this.competenceId}/comments/${id}?target=add`, {
                    content: this.editedContent,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.setComment(response.data, false);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$refs.form.reset();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        },
        async deleteComment() {
            this.loadingBtn = true;
            try {

                const request = await apiService.delete(`/comments/${this.itemToDelete}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                await this.retrieveData();
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$refs.form.reset();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false;
            }
        }
    }
}