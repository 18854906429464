import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        competenceEvalRow: {
            default: null
        },
    },

    watch: {

    },

    data() {
        return {
            valid: true,
            loading: false,
            selectedStatus: null,
            status: [
                { label: 'Acquis', value: 1 },
                { label: 'Non acquis', value: 2 },
                { label: 'Partiel', value: 3 },
            ],
            rules: { required: value => !!value || 'Champs requis', },

        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.$refs.form.reset();
            this.$emit("update:dialog", false)
        },
        async actionHandler() {
            this.loading = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                let competenceEvalRowId = this.competenceEvalRow.evaluation != null ? this.competenceEvalRow.evaluation.id : this.competenceEvalRow.id
                const request = await apiService.post(`/evaluations/responses?target=competence`, { userEval: competenceEvalRowId, mark: this.selectedStatus.value })
                
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$emit("refresh", { currentData: response.data, currentEval: response.currentEval, competences: response.competences });
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}