import defaultRoutes from '../router/defaultRoutes';
import evaluationRoutes from '../router/evaluationRoutes';
import organizationRoutes from '../router/organizationRoutes';
import administrationRoutes from '../router/administrationRoutes';
import competenceRoutes from '../router/competenceRoutes';
import skillBaseRoutes from '../router/skillBasesRoutes';
import referenceLibraryRoutes from '../router/referenceLibraryRoutes';
import profilRoutes from '../router/ProfilRoutes';
import generalRoutes from '../router/generalRoutes';
export const API_URL = process.env.VUE_APP_API_URL;
export const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME;

export const NAV_ADMIN = [
    { title: 'Accueil', action: 'organizations', actionType: 'page', isActive: false, isIcon: false, image: "/images/logo.png", icon: "" },
    { title: 'Tableau de bord', action: 'organizations', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-home" },
    { title: 'Administrations', action: 'administrations', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-folder-account" },
    { title: 'Référentiels', action: 'skillsBases', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-clipboard-list" },
    { title: 'Bibliothèques', action: 'referenceLibraries', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-book-open-blank-variant" },
    { title: 'Evaluations', action: 'evaluations', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-clipboard-list" },
    { title: 'Les compétences', action: 'competences', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-toolbox" },
]

export const NAV_REFERENT = [
    { title: 'Accueil', action: 'generalOrganization', actionType: 'page', isActive: false, isIcon: false, image: "/images/logo.png", icon: "" },
    { title: 'Tableau de bord', action: 'generalOrganization', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-home" },
    { title: 'Référentiels', action: 'skillsBases', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-clipboard-list" },
    { title: 'Bibliothèques', action: 'referenceLibraries', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-book-open-blank-variant" },
    { title: 'Les compétences', action: 'competences', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-toolbox" },
]

export const NAV_TUTOR = [
    { title: 'Accueil', action: 'generalOrganization', actionType: 'page', isActive: false, isIcon: false, image: "/images/logo.png", icon: "" },
    { title: 'Tableau de bord', action: 'generalOrganization', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-home" },
    { title: 'Référentiels', action: 'skillsBases', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-clipboard-list" },
    { title: 'Bibliothèques', action: 'referenceLibraries', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-book-open-blank-variant" },
    { title: 'Evaluations', action: 'evaluations', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-clipboard-list" },
    { title: 'Les compétences', action: 'competences', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-toolbox" },
]

export const NAV_LEARNER = [
    { title: 'Accueil', action: 'dashboard', actionType: 'page', isActive: false, isIcon: false, image: "/images/logo.png", icon: "" },
    { title: 'Tableau de bord', action: 'dashboard', actionType: 'page', isActive: false, isIcon: true, image: "", icon: "mdi-home" },
]

let routes = [];

routes = routes.concat(
    defaultRoutes, profilRoutes, evaluationRoutes,
    organizationRoutes, competenceRoutes, skillBaseRoutes,
   generalRoutes, administrationRoutes, referenceLibraryRoutes
  )

export const routeList = routes;