import apiService from "@/services/apiService";
import EvaluationModelCard from "@/components/card/EvaluationModelCard.vue";
import DeleteEvaluationModelDialog from "@/components/dialog/DeleteEvaluationModelDialog.vue";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore"


export default {
    setup() {

    },

    components: {
        EvaluationModelCard,
        DeleteEvaluationModelDialog
    },

    props: {

    },
    data() {
        return {
            loading: true,
            showTrash: false,

            items: [],

            filters: [
                { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
                { label: 'Trier par : Ordre alphabétique Z-A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
            total: 0,
            currentPage: 1,
            lastPage: 1,

            toDeleteId: 0,
            dialogEvaluationModelDelete: false,
            evaluationModelLength: 0,
            userInfo: null
        }
    },
    computed: {
        // displayed grouped evaluations models by skillbase
        groupedItems() {
            const grouped = {};
            this.items.forEach((item) => {

                const skillBaseLabel = item.label;
                const skillBaseId = item.id;

                if (!grouped[skillBaseLabel]) {
                    grouped[skillBaseLabel] = [];
                }
                grouped[skillBaseLabel].push({ ...item, skillBaseId });

            });

            return grouped;
        },
    },
    watch: {
        '$route'() {
            this.fetchEvaluationModels()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => {})
            this.$router.push({ name: this.$route.name, query: { search: val, filter: this.selectedFilter.value }, replace: true }).catch(() => {})
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search, filter: val.value };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => {})
        },
    },

    

    created() {
        const authStore = useAuthStore()
        this.userInfo = authStore.info;
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' };
        this.fetchEvaluationModels();
    },

    mounted() {

    },

    methods: {
        openDeleteDialog(id) {
            this.toDeleteId = id
            this.dialogEvaluationModelDelete = true;
        },
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.fetchEvaluationModels();
        },
        changePage() {
            this.fetchEvaluationModels();
        },
        async fetchEvaluationModels() {
            this.loading = true
            try {
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                if(this.showTrash) queryString += '&target=trash'
                const url = `/evaluation-models?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;

                // Filter out items that have an evaluation_id to null 
                if (this.userInfo.role != "admin" && this.userInfo.role != "superadmin" ){
                    this.items = (!this.showTrash) ? this.items.filter(data => data.evaluation_id == null &&  data.createdBy != null && data.createdBy.id == this.userInfo.id && !data.isArchived) :
                    this.items.filter(data => data.evaluation_id == null &&  data.createdBy != null && data.createdBy.id == this.userInfo.id && data.isArchived)     
                }else{
                    this.items = (!this.showTrash) ? this.items.filter(data => data.evaluation_id == null) :
                    this.items.filter(data => data.evaluation_id == null)     
                }


                this.evaluationModelLength = this.items.reduce((somme, item) => {
                    const count = item.evaluationModels.filter(evaluation => evaluation.evaluation_id === null).length;
                    return somme + count;
                }, 0).toString();

                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;

                // open the panels by default
                this.panel = Array.from(Array(this.items.length).keys());
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false
            }
        },
        async removeEvaluationModel(){
            try {
                const request = await apiService.delete(`/evaluation-models/${this.toDeleteId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                await this.fetchEvaluationModels();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    },
}