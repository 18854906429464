export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: ""
        },
        label: {
            default: ""
        },
        createdBy: {
            default: null
        },
        createdAt: {
            default: null
        },
        evaluationCriteria: {
            default: null
        },
        showTrash: {
            default: false
        },
        canDelete: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {
            panel: true
        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        deleteItem() {
            this.$emit("deleted", this.id,)
        },
        goToDetail() {
            if (this.showTrash) return this.$router.push({ name: "competenceForm", params: { id: this.id }, query: { archived: true } })
            return this.$router.push({ name: "competenceForm", params: { id: this.id } })
        }
    },
}