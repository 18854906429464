import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import EvaluationCompetencesListLearner from "@/components/list/evaluation/EvaluationCompetencesListLearner.vue"
import EvaluationCompetencesListTutor from "@/components/list/evaluation/EvaluationCompetencesListTutor.vue"
import { useAuthStore } from "@/stores/AuthStore";

import CommentsDrawer from "@/components/drawer/CommentsDrawer.vue"
import ResourcesDrawer from "@/components/drawer/ResourcesDrawer.vue"
import UserEvalResponseHistoriesDrawer from "@/components/drawer/UserEvalResponseHistoriesDrawer.vue"
import { API_URL } from "@/utils/constantes";
import authTokenService from "@/services/authTokenService";

import ToggleEnableUserEvalDialog from "@/components/dialog/ToggleEnableUserEvalDialog.vue"

export default {
    setup() { },

    components: {
        EvaluationCompetencesListLearner,
        EvaluationCompetencesListTutor,
        CommentsDrawer,
        ResourcesDrawer,
        ToggleEnableUserEvalDialog,
        UserEvalResponseHistoriesDrawer,
    },

    props: {},

    watch: {
        '$route'() {
            this.fetchEvaluation()
        },
        selectedEvaluation(val) {
            if (this.$route.params.evaluationId == val.id) return;
            const authStore = useAuthStore();

            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') {
                this.$router.push({
                    name: 'evaluationDetail',
                    replace: true,
                    params: {
                        organizationId: this.$route.params.organizationId,
                        evaluationId: val.id,
                        learnerId: this.$route.params.learnerId,
                    },
                })
            } else if (authStore.info.role == 'learner') {
                this.$router.push({
                    name: 'singleEvaluation',
                    replace: true,
                    params: { evaluationId: val.id, },
                })
            } else {
                this.$router.push({
                    name: 'singleEvaluationDetail',
                    replace: true,
                    params: {
                        evaluationId: val.id,
                        learnerId: this.$route.params.learnerId,
                    },
                })
            }
        }
    },

    data() {
        return {
            loading: true,
            loadingSummary: false,
            item: null,
            evaluations: [],
            selectedEvaluation: {},

            infoRole: null,

            evalByTutor: [],
            evalByUser: [],
            compareEval: false,
            validCompetenceByTutor: 0,
            validCompetenceByLearner: 0,
            isCompleted: false,
            isEnabled: true,

            drawerHistory: null,
            drawerComments: null,
            drawerResources: null,
            drawerLearnerResources: null,
            drawerTutorResources: null,
            selectedCompetence: null,
            cover: null,

            skillBases: [],
            titlePart: null,
            toggleEnableDialog: false,
            showCompareBtn: false,
            isPersonnalized: false,
            tutorEval: []
        }
    },

    created() {
        this.fetchEvaluation();
    },

    mounted() { },

    methods: {
        goBack() {
            const authStore = useAuthStore();
            if(authStore.info.role == 'superadmin') {
                this.$router.go(-1)
                return;
            }

            if (authStore.info.role == 'admin') {
                return this.$router.push({
                    name: "singleEvaluationProfil",
                    replace: true,
                    params: {
                        organizationId: this.$route.params.organizationId,
                        learnerId: this.$route.params.learnerId,
                    }
                })
            }

            if (authStore.info.role == 'tutor' || authStore.info.role == 'referent') {
                return this.$router.push({
                    name: "evaluationProfil",
                    replace: true,
                    params: { learnerId: this.$route.params.learnerId, }
                })
            }

            return this.$router.push({
                name: "dashboard",
                replace: true,
            })
        },
        showComments(competence) {
            this.selectedCompetence = competence;
            this.drawerComments = true
        },
        showResources(competence) {
            this.selectedCompetence = competence;
            this.drawerResources = true;
        },
        showHistory(competence) {
            this.selectedCompetence = competence;
            this.drawerHistory = true;
        },
        updateCommentDrawer(val) {
            this.drawerComments = val;
            if (!val) this.selectedCompetence = null;
        },
        updateResourceDrawer(val) {
            this.drawerResources = val;
            if (!val) this.selectedCompetence = null;
        },
        updateHistoryDrawer(val) {
            this.drawerHistory = val;
            if (!val) this.selectedCompetence = null;
        },
        changeEnableState() {
            this.toggleEnableDialog = true;
        },
        resetValue() {
            this.isEnabled = this.item.isEnabled
        },
        setEnableValue(currentData) {
            this.item.isEnabled = currentData
        },
        async goToDetail(source) {
            let routeData = null;
            let query =  null
            if (source != null) {
               query =  {
                source: source
            } }
            if (this.item.evaluation && this.item.evaluation != null) {
                if (this.infoRole == "tutor" || this.infoRole == "superadmin" || this.infoRole == "admin") {
                    routeData = this.$router.resolve({
                        name: "evaluationLearnerFormViewForTutor",
                        params: {
                            evaluationId: this.item.evaluation.id,
                            learnerId: this.item.learner.id,
                            evaluationModelId: this.item.evaluationModel.id
                        },
                        query : query
                    });

                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push({
                        name: "evaluationLearnerFormView",
                        params: {
                            evaluationId: this.item.evaluation.id,
                            learnerId: this.item.learner.id,
                            evaluationModelId: this.item.evaluationModel.id,
                        },
                        query : query
                    });

                }
            }
        },
        refreshData({ currentEval, competences }) {
            this.item = currentEval;
            this.evalByTutor = competences.filter(el => !el.isSelf);
            this.evalByUser = competences.filter(el => el.isSelf);
            this.validCompetenceByTutor = this.evalByTutor.filter(el => el.status == "Acquis").length
            this.validCompetenceByLearner = this.evalByUser.filter(el => el.status == "Acquis").length
            this.toogleShowCompareBtn();
        },
        toogleShowCompareBtn() {
            if (this.infoRole == 'tutor') {
                this.showCompareBtn = this.item.showToTutor;
            } else if (this.infoRole == 'learner') {
                this.showCompareBtn = this.item.showToLearner;
            } else {
                this.showCompareBtn = true;
            }

            if (!this.showCompareBtn) this.compareEval = false;
        },
        async getDistantPath() {
            try {
                if (this.item.learner.organization.cover == null) return;
                const request = await fetch(`${API_URL}/medias/${this.item.learner.organization.cover.label}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })

                if (!request.ok) throw new Error("Une erreur est survenue");
                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);
                this.cover = objUrl;
            } catch (error) {
                this.cover = "";
            }
        },
        async fetchEvaluation() {
            this.loading = true;
            try {
                const authStore = useAuthStore();
                this.infoRole = authStore.info.role;

                const request = await apiService.get(`/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/evaluations/${this.$route.params.evaluationId}/learners/${this.$route.params.learnerId ?? authStore.info.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.item = response.currentEval;
                this.tutorEval = response.currentTutorEval;

                this.isEnabled = this.item.isEnabled

                const availableEvaluations = [];
                response.otherEvals.forEach(evalu => {
                    if (evalu.evaluation) {
                        evalu.evaluation.selected = false
                        availableEvaluations.push(evalu.evaluation);
                    }
                })

                this.evaluations = availableEvaluations;
                const selectedEval = availableEvaluations.find(el => el.id == this.$route.params.evaluationId)

                this.titlePart = `${this.item.learner.lastname} ${this.item.learner.firstname} ${this.item.evaluation.label}`
                this.titlePart = this.titlePart.trim();
                this.titlePart = this.titlePart.replaceAll(" ", "_")
                this.titlePart = this.titlePart.replaceAll(".", "")

                if (selectedEval) {
                    selectedEval.selected = true;
                    this.selectedEvaluation = selectedEval;
                    const parentCompetences = response.currentEval.evaluationModel.competences.filter(competence => competence.is_parent == 1);
                    const parentCompetencesLength = parentCompetences.length;
                    this.selectedEvaluation.competences = parentCompetencesLength
                    this.isCompleted = response.currentEval.evaluationModel.is_completed
                    this.isPersonnalized = response.currentEval.evaluationModel.is_personnalized

                    this.evalByUser = response.competences
                    this.evalByTutor = response.competences.filter(el => !el.isSelf && el.competence.is_parent == 1);
                    this.evalByUser = response.competences.filter(el => el.isSelf && el.competence.is_parent == 1);
                    this.validCompetenceByTutor = this.evalByTutor.filter(el => el.status == "Acquis").length
                    this.validCompetenceByLearner = this.evalByUser.filter(el => el.status == "Acquis").length
                }

                this.getDistantPath();
                this.toogleShowCompareBtn();
            } catch (error) {
                this.goBack();
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },
        async downloadRecap() {
            this.loadingSummary = true;
            const authStore = useAuthStore();

            try {
                const request = await fetch(`${API_URL}/organizations/${this.$route.params.organizationId ?? authStore.info.organization.id}/evaluations/${this.$route.params.evaluationId}/learners/${this.$route.params.learnerId ?? authStore.info.id}/summary`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authTokenService.getToken(),
                    },
                })
                if (!request.ok) throw new Error("Une erreur est survenue");

                const response = await request.blob();
                const newBlob = new Blob([response], { type: response.type });
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = `recap_evaluation_${this.titlePart}_${new Date().getTime()}`;
                link.classList.add('d-none');
                link.click();
                window.URL.revokeObjectURL(link.href);
                link.remove();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingSummary = false
            }
        }
    }
}