import { useAuthStore } from "@/stores/AuthStore";
import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue";
import EvalCompetenceDialog from '@/components/dialog/EvalCompetenceDialog.vue';
import ReEvaluateCompetenceDialog from '@/components/dialog/ReEvaluateCompetenceDialog.vue';
import EvaluationCompetencesListLearner from "@/components/list/evaluation/EvaluationCompetencesListLearner.vue";
import EvaluationCompetencesListTutor from "@/components/list/evaluation/EvaluationCompetencesListTutor.vue";
import CompetenceForm from "@/components/form/online/evaluation/CompetenceForm.vue";
import CompetenceWithFormTemplateChoiceCard from "@/components/card/CompetenceWithFormTemplateChoiceCard.vue";
import ToggleEnableUserEvalDialog from "@/components/dialog/ToggleEnableUserEvalDialog.vue"
import CommentsDrawer from "@/components/drawer/CommentsDrawer.vue";
import ResourcesDrawer from "@/components/drawer/ResourcesDrawer.vue";
import UserEvalResponseHistoriesDrawer from "@/components/drawer/UserEvalResponseHistoriesDrawer.vue";

import { formMixin } from '@/utils/formMixin';
import { evaluationFormMixin } from "@/utils/evaluationFormMixin";
import { evaluationMixin } from '@/utils/evaluationMixin';
import { utilMixin } from '@/utils/utilMixin';


export default {
    setup() { },

    components: {
        RestoreOrDeleteDialog,
        EvalCompetenceDialog,
        ReEvaluateCompetenceDialog,
        CompetenceWithFormTemplateChoiceCard,
        EvaluationCompetencesListLearner,
        EvaluationCompetencesListTutor,
        CommentsDrawer,
        ResourcesDrawer,
        UserEvalResponseHistoriesDrawer,
        ToggleEnableUserEvalDialog,
        CompetenceForm

    },
    mixins: [formMixin, evaluationMixin, evaluationFormMixin, utilMixin],

    data() {
        return {
            role: '',
            creator: null,
            rules: { required: value => !!value || 'Champs requis', },

            isFormPreview: false,
            loading: true,
            loadingBtn: false,
            assignLearnersDialog: false,
            assignCompetencesDialog: false,

            label: null,
            description: null,
            isEnabled: true,
            valid: true,
            isArchived: false,

            skillBase: {},
            skillBasesList: [],
            competences: [],

            learner: null,
            learners: [],
            learnerCompetences: [],
            panel: [],

            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            evaluationId: this.$route.params.id ? true : false,

            competenceTemplateFields: [],
            learnerCompetenceFields: [],
            openSendEvaluationConfirmDialog: false,
            formCompetenceFields: [],
            groupedCompetences: {},
            competenceToEvaluationCount: 0
        }
    },

    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        await this.fetchSingleEvaluation();
    },
    mounted() {
        const anchor = localStorage.getItem('navigationAnchor');
        if (anchor != null) {
            this.$nextTick(() => {
                setTimeout(() => {
                    const element = document.querySelector(`#${anchor}`);

                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                        localStorage.removeItem('navigationAnchor');
                    } else {
                        console.error('Element not found:', anchor);
                    }
                }, 800);
            });
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        toggleFormPreview() {
            this.isFormPreview = !this.isFormPreview;
        },
        updateCompetences(data) {
            this.competences = data;
        },
        async refreshEvaluationData({ currentEval, competences }) {
            this.item = currentEval;
            this.evalByTutor = competences.filter(el => !el.isSelf);
            this.evalByUser  = competences.filter(el => el.isSelf);
            this.validCompetenceByTutor   = this.evalByTutor.filter(el => el.status == "Acquis" && el.competence.is_parent).length
            this.validCompetenceByLearner = this.evalByUser.filter(el => el.status == "Acquis" && el.competence.is_parent).length
            // refresh view display without reloading
            this.groupCompetencesByParent();
        },
        setSelectedEvalCompetence(item) {
            this.evalDialog = true;
            this.selectedEvalCompetence = item;
        },
        setAdditionEvaluationFields(evaluationFields) {
            this.additionnalFields = [];
            JSON.parse(evaluationFields).forEach(async field => {
                await this.setFormStructure(field);
            });
        },
        async fetchSkillBases() {
            this.loading = true;
            try {
                const request = await apiService.get(`/skill-bases?target=all`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.skillBasesList = response.data;
            } catch (error) {
                this.skillBasesList = [];
            } finally {
                this.loading = false;
            }
        },
        openConfirmDialog() {
            this.openSendEvaluationConfirmDialog = true;
        },
        close() {
            this.openSendEvaluationConfirmDialog = false;
        },
        setEvaluationSourceByUser(response){
            if (this.$route.query.source == "tutor"){
                this.validCompetenceByLearner = this.validCompetenceByTutor 
                return this.tutorEval.evaluationModel
            } else {
                return response.data
            } 
        },
        async fetchSingleEvaluation() {
            this.loading = true;
            try {
                await this.fetchEvaluationInfo();
                let target = "";
                let template = null;
                if (this.$route.query.archived) target = "?target=trash";

                const request = await apiService.get(`/evaluation-models/${this.$route.params.evaluationModelId}${target}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                template = this.setEvaluationSourceByUser(response);

                this.label = response.data.label;
                this.isEnabled = response.data.isEnabled;
                this.learners = [];
                this.competences = [];
                this.skillBase = response.data.skillBase;
                this.skillBasesList = [this.skillBase];
                this.competences = response.data.competences;
                this.competenceTemplateFields = this.parseData(template.competencesFields);

                this.competenceToEvaluationCount = this.competences.filter(el => el.is_parent).length;
                this.isEditing = this.isCompleted;
                this.groupCompetencesByParent();
                
                this.formCompetenceFields = this.competenceTemplateFields;
                this.setAdditionEvaluationFields(template.evaluationFields);
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async sendCompletedEvaluation() {
            this.loadingBtn = true;
            try {
                this.openSendEvaluationConfirmDialog = false;
                this.valid = this.checkFormFieldsValues();
                if (!this.valid) return;

                let allFieldsValid = true;
                this.groupedCompetences.forEach((fields, structureIndex) => {
                    if (fields.structure && !this.validateStructureFieldsValues(this.parseData(fields.structure))) {
                        allFieldsValid = false;
                        this.$set(this.groupedCompetences, structureIndex, fields);

                    } else {
                        // Reflect validated fields back to formCompetenceFields to update the view
                        this.$set(this.groupedCompetences, structureIndex, fields);
                    }
                });

                // Validate structure fields
                if (!allFieldsValid) return;

                const competencesToSend = this.competences.map(el => el.id);
                const url = `/user-evaluation/completed`;
                const request = await apiService.post(url, {
                    competences: competencesToSend,
                    learner_id: this.$route.params.learnerId,
                    evaluation_id: this.$route.params.evaluationId,
                    evaluation_model_id: this.$route.params.evaluationModelId,
                    structure_fields_evaluation: this.additionnalFields,
                    structure_fields_competence: this.groupedCompetences,
                });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false });
                this.defaultDisable = true;
                this.goBack();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                });
            } finally {
                this.loadingBtn = false;
            }
        },
    }
}
