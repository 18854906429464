import NotificationList from "@/components/list/notifications/NotificationList.vue";

export default {
    setup() { },

    components: {
        NotificationList,
    },

    props: {},

    watch: {},

    data() {
        return {
            
        }
    },

    created() {

    },

    mounted() { },

    methods: {

    }
}