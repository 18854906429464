export default {
    setup() { },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        selectedActionType: {
            default: ""
        }
    },

    watch: {

    },

    data() {
        return {
            loading: false,
        }
    },

    created() {
    },

    mounted() { },

    methods: {
        close() {
            this.$emit("resetValue")
            this.$emit("update:dialog", false)
        },
        actionHandler() {
            if(this.selectedActionType == "") return;
            this.$emit(this.selectedActionType);
            this.$emit("update:dialog", false)
        },
    }
}