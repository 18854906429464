import NavbarComponent from "@/components/navigation/NavbarComponent.vue"

export default {
    components: {
        NavbarComponent,
    },

    props: {},

    watch: {

    },

    data() {
        return {};
    },

    created() {

    },

    mounted() { },

    methods: {
        authentication(payload) {
            this.$emit("authentication", payload)
        }
    },
};