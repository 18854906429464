import EvaluationList from "@/components/list/evaluation/EvaluationList.vue"
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() { },

    components: {
        EvaluationList,
    },

    props: {},

    watch: {

    },

    data() {
        return {
            role: '',
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        goToCreate() {
            this.$router.push({ name: 'evaluationForm' })
        },
        goToModelList() {
            this.$router.push({ name: 'evaluationModels' })
        }
    }
}