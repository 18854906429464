export default {
    setup() { },

    components: {},

    props: {
        skillBaseId: {
            default: 0
        }, 
        referenceId: {
            default: 0
        },
        organizationId: {
            default: 0
        },
        label: {
            default: ""
        },
        createdAt : {
            default: null
        },
        canDelete : {
            default: false
        },
        createdBy: {
            default: null
        },
        competenceCount: {
            default: 0
        },
        isArchived: {
            default: false
        },
        context: {
            default: 'referenceLibraries'
        }
    },

    watch: {},

    data() {
        return {}
    },

    created() { 
    },

    mounted() { },

    methods: {
        deleteItem() {
            this.$emit("deleted", [this.referenceId, this.organizationId, this.skillBaseId])
        },
        goToDetail() {
            let routeName;
            let id;

            switch (this.context) {
                case 'archived':
                  routeName = "archivedReferenceForm";
                  id =  this.referenceId;
                  break;
                case 'skillBases':
                  routeName = "skillsBasesForm";
                  id =  this.skillBaseId;
                  break;
                default:
                  routeName = "referenceLibrariesForm";
                  id =  this.referenceId;
                  break;
              }
        
            
            if (this.isArchived) return this.$router.push({ name:  routeName, params: { id: id }, query: { archived: true } })
            return this.$router.push({ name: routeName, params: { id: id } })
        }
    }
}