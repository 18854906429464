import CompetenceView from "@/views/online/competence/CompetenceView.vue";
import CompetenceFormView from "@/views/online/competence/CompetenceFormView.vue";
import CompetenceFormTemplateView from "@/views/online/competence/CompetenceFormTemplateView.vue";
import CompetenceModelsView from "@/views/online/competence/CompetenceModelsView.vue";

const competenceRoutes = [
    {
        path: "/competences",
        name: "competences",
        component: CompetenceView,
        meta: { requiredAuth: true, roles: ["superadmin", "admin", "referent", "tutor"] }
    },
    {
        path: "/competences/formulaire/:id?",
        name: "competenceForm",
        component: CompetenceFormView,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
    {
        path: "/competences/:competenceId?/modele/:id?",
        name: "competenceFormTemplate",
        component: CompetenceFormTemplateView,
        meta: { requiredAuth: true, roles: ["superadmin","admin", "referent", "tutor"] }
    },
    {
        path: "/competences/modeles",
        name: "competenceModels",
        component: CompetenceModelsView,
        meta: { requiredAuth: true, roles: ["superadmin","admin","tutor", "referent"] }
    },
]

export default competenceRoutes;