import OfflineLayout from "../OfflineLayout.vue";
import OnlineLayout from "../OnlineLayout.vue";
import LoadingLayout from "../LoadingLayout.vue";

import jwtDecode from 'jwt-decode'
import { useAuthStore } from '@/stores/AuthStore';
import authTokenService from '@/services/authTokenService';
import apiService from '@/services/apiService';

import FooterComponent from "@/components/general/FooterComponent.vue"
import SnackbarNotification from "@/components/notifications/SnackbarNotification.vue"

export default {
    setup() {
    },

    components: {
        'offline-layout': OfflineLayout,
        'online-layout': OnlineLayout,
        'loading-layout': LoadingLayout,
        FooterComponent,
        SnackbarNotification
    },

    props: {},

    watch: {

    },

    data() {
        return {
            layout: !authTokenService.getToken() ? 'offline-layout' : 'online-layout',
        };
    },

    created() {
        this.autoRefresh();
    },

    mounted() { },

    methods: {
        authentication(payload) {
            if (!payload) {
                this.layout = 'offline-layout'
                this.logout()
                this.$router.push({ name: 'login', replace: true })
            } else {
                this.autoRefresh();
                this.layout = 'online-layout'
            }
        },
        async refresh() {
            try {
                const authStore = useAuthStore()
                const request = await apiService.post('/refresh', { refreshToken: authStore.refreshToken })
                const response = await request.json();
                if(!request.ok) throw new Error("Une erreur est survenue");

                authStore.token = response.token
                authStore.refreshToken = response.refreshToken
                authTokenService.setToken(response.token);

                const decoded = jwtDecode(response.token)
                const date = decoded.exp * 1000
                const dateNow = Date.now()
                if (this.timerAuth != null) clearTimeout(this.timerAuth)
                this.timerAuth = setTimeout(this.refresh, date - dateNow)
            } catch (error) {
                this.layout = 'offline-layout'
                this.logout()
                this.$router.push({ name: 'login', replace: true })
            }
        },

        autoRefresh() {
            const token = authTokenService.getToken();
            if (!token) return

            const decoded = jwtDecode(token)
            const date = decoded.exp * 1000
            const dateNow = Date.now()
            if (this.timerAuth != null) clearTimeout(this.timerAuth)
            this.timerAuth = setTimeout(this.refresh, date - dateNow)
        },

        logout() {
            const authStore = useAuthStore()
            authStore.token = null
            authStore.info = { lastname: null, firstname: null, role: null }
            authStore.refreshToken = null
            authTokenService.clearAuth();
            if (this.timerAuth != null) clearTimeout(this.timerAuth)
        },
    },
};