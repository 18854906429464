import apiService from "@/services/apiService";
import TutorEvaluationCard from "@/components/card/TutorEvaluationCard.vue";
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {
        TutorEvaluationCard,
    },

    props: {

    },

    data() {
        return {
            loading: true,
            showTrash: false,

            items: [],
            filters: [
                { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
                { label: 'Trier par : Ordre alphabétique Z-A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' },
            total: 0,
            currentPage: 1,
            lastPage: 1,
            evaluationLength:0,
            userInfo: null
        }
    },

    watch: {
        '$route'() {
            this.fetchEvaluations()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => { })
            this.$router.push({ name: this.$route.name, query: { search: val, filter: this.selectedFilter.value }, replace: true }).catch(() => { })
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search, filter: val.value };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => { })
        },
    },
    computed: {
        // displayed grouped evaluations by skillbase
        groupedItems() {
            const grouped = {};
            this.items.forEach((item) => {
                const skillBaseLabel = item.label;
                const skillBaseId = item.id;

                if (!grouped[skillBaseLabel]) {
                    grouped[skillBaseLabel] = [];
                }
                grouped[skillBaseLabel].push({ ...item, skillBaseId });
            });

            return grouped;
        },
    },
    created() {
        const authStore = useAuthStore()
        this.userInfo = authStore.info;
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier par : Ordre alphabétique A-Z', value: 'asc' };
        this.fetchEvaluations();
    },

    mounted() {

    },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.fetchEvaluations();
        },
        changePage() {
            this.fetchEvaluations();
        },
        async fetchEvaluations() {
            this.loading = true
            try {
                
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                if (this.showTrash) queryString += '&target=trash'
                const url = `/evaluations?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                let filteredData = response.data;
                // assures to get only skillbase that the current user can get
                if (this.userInfo.role == "tutor"){
                    this.items = filteredData.filter(el => el.createdBy != null && el.createdBy.id == this.userInfo.id);
                }else{
                    this.items = filteredData
                }
                
                this.evaluationLength = this.items.reduce((somme, item) => {
                    return somme +  item.evaluations.length;
                }, 0).toString();

                this.total = this.items.length;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;

                // to get panels open by default 
                this.panel = Array.from(Array(filteredData.length).keys());
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false
            }
        }
    },
}